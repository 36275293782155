import { ComparisonView, SingleView } from "./Row"

export const SeriesList = ({
  series,
  comparisonSeries,
  isComparisonPresent,
  toggleHoveredLines,
  colors,
  valueFormat,
  valueInput,
  resolution,
  valuePrecision,
  invertDate,
  comparisonInvertDate,
}) => (
  <ul className="py-1">
    {isComparisonPresent
      ? series.map((serie) => (
          <ComparisonView
            key={serie.id}
            baseSerie={serie}
            comparisonSerie={comparisonSeries.find(({ label }) => label === serie.label)}
            valueFormat={valueFormat}
            valueInput={valueInput}
            resolution={resolution}
            valuePrecision={valuePrecision}
            colors={colors}
            toggleHoveredLines={toggleHoveredLines}
            invertDate={invertDate}
            comparisonInvertDate={comparisonInvertDate}
          />
        ))
      : series.map((serie) => (
          <SingleView
            key={serie.id}
            serie={serie}
            toggleHoveredLines={toggleHoveredLines}
            valueFormat={valueFormat}
            valueInput={valueInput}
            resolution={resolution}
            valuePrecision={valuePrecision}
            colors={colors}
          />
        ))}
  </ul>
)

SeriesList.propTypes = {
  series: PropTypes.array.isRequired,
  comparisonSeries: PropTypes.array,
  isComparisonPresent: PropTypes.bool.isRequired,
  toggleHoveredLines: PropTypes.func.isRequired,
  colors: PropTypes.func.isRequired,
  valueFormat: PropTypes.string.isRequired,
  valueInput: PropTypes.string,
  resolution: PropTypes.string.isRequired,
  valuePrecision: PropTypes.number.isRequired,
  invertDate: PropTypes.instanceOf(Date),
  comparisonInvertDate: PropTypes.instanceOf(Date),
}
