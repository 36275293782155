import dayjs from "dayjs"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import FormattedTime from "#Root/formatters/FormattedTime"

import { X_AXIS_HEIGHT, Y_AXIS_WIDTH } from "../constants"
import { useChartGraphContext } from "../contexts/GraphContext"

// This component has zero knowledge about anything context related.
// It's the core logic that places the x-axis on the chart.
// It's a pure component that can be using in any chart scenario
export const XAxisCore = ({
  canvasHeight,
  canvasWidth,
  gridLinesTop,
  showGridLines = true,
  tickInterval,
  tickSize = 100,
  top,
  width,
  xAxisHeight,
  xDomain,
  xScale,
  yAxisWidth,
}) => {
  xScale.domain(xDomain)

  const ticks = xScale.ticks.apply(xScale, [tickInterval || Math.round(width / tickSize)])

  const scaleStart = dayjs(xScale.domain()[0])
  const scaleEnd = dayjs(xScale.domain()[1])

  const hourDifference = scaleEnd.diff(scaleStart, "hours")

  const format = hourDifference <= 48 ? "time" : "date"

  return (
    <>
      {showGridLines &&
        ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute h-px w-px z-0 pointer-events-none border-l border-gray-200/75 border-dashed x-axis-grid-line"
            style={{
              left: xScale(tick) + yAxisWidth,
              height: canvasHeight,
              top: gridLinesTop,
            }}
          />
        ))}
      <div
        className="absolute text-ms text-gray-600 pt-3"
        style={{
          top,
          left: width - canvasWidth,
          width: canvasWidth,
          height: xAxisHeight,
        }}
      >
        {ticks.map((tick, i) => (
          <div key={i}>
            <span
              style={{ left: `${xScale(tick)}px` }}
              className="absolute block whitespace-nowrap -translate-x-1/2 select-none"
            >
              <FormattedTime value={tick} format={format} />
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

const XAxis = ({ tickInterval, tickSize = 100 }) => {
  const { settings } = useChartSettingsContext()
  const { width, canvasWidth, canvasHeight, xScale, domain, hasYAxis } = useChartGraphContext()

  return (
    <XAxisCore
      canvasHeight={canvasHeight}
      canvasWidth={canvasWidth}
      xAxisHeight={X_AXIS_HEIGHT}
      tickInterval={tickInterval}
      tickSize={tickSize}
      width={width}
      xDomain={domain.x}
      xScale={xScale}
      yAxisWidth={hasYAxis ? Y_AXIS_WIDTH : 0}
      top={canvasHeight}
      gridLinesTop={0}
      showGridLines={settings.verticalSupportLines}
    />
  )
}

XAxis.propTypes = {
  tickInterval: PropTypes.func,
  tickSize: PropTypes.number,
}

XAxisCore.propTypes = {
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  xAxisHeight: PropTypes.number,
  tickInterval: PropTypes.func,
  tickSize: PropTypes.number,
  width: PropTypes.number,
  xDomain: PropTypes.array,
  xScale: PropTypes.func,
  yAxisWidth: PropTypes.number,
  top: PropTypes.number,
  gridLinesTop: PropTypes.number,
  showGridLines: PropTypes.bool,
}

XAxis.displayName = "XAxis"

export default XAxis
