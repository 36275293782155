import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import TimestampSelector from "#Root/components/filters/DateTimeFilter/TimestampSelector"
import { useDatePickerContext } from "#Root/contexts/DatePickerContext"
import FormattedTime from "#Root/formatters/FormattedTime/FormattedTime"
import { Button } from "#Root/ui"
import DateRangePicker from "#Root/ui/DateRangePicker/DateRangePicker"

import { useFullscreenChartsContext } from "../contexts/fullscreenChartsContext"
import { getComparisonEnd } from "../utils/timerange"

dayjs.extend(utc)

export const getFriendlyRangeLabel = ({ from, to }) => {
  return (
    <>
      <FormattedTime format="short" value={from} />
      {to && " - "}
      {to && <FormattedTime format="short" value={to} />}
    </>
  )
}

const DatePickerButton = React.forwardRef(({ onClick }, _ref) => {
  const { range } = useDatePickerContext()

  return (
    <Button onClick={onClick} color="white">
      {getFriendlyRangeLabel(range)}
    </Button>
  )
})

DatePickerButton.displayName = "DatePickerButton"

DatePickerButton.propTypes = {
  onClick: PropTypes.func,
}

export const FullscreenDatePicker = () => {
  const { updateBaseTimeRange, baseTimeRange } = useFullscreenChartsContext()

  return (
    <div className="relative">
      <DateRangePicker
        onChange={({ start, end, timeframe }) =>
          updateBaseTimeRange({
            from: start.toISOString(),
            to: end.toISOString(),
            timeframe,
          })
        }
        value={baseTimeRange}
        hideMinutely={true}
        button={<DatePickerButton />}
      />
    </div>
  )
}

export const ComparisonButton = () => {
  const { baseTimeRange } = useFullscreenChartsContext()
  const { setComparisonTimeRange, comparisonTimeRange } = useFullscreenChartsContext()

  const [startDate, setStartDate] = React.useState(comparisonTimeRange?.from || baseTimeRange.from)
  const [endDate, setEndDate] = React.useState(comparisonTimeRange?.to || null)

  // If we clear the comparison, reset the date range
  React.useEffect(() => {
    if (!comparisonTimeRange) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [comparisonTimeRange])

  const handleDateChange = (date) => {
    setStartDate(date)

    const dateEnd = getComparisonEnd(date, baseTimeRange)
    setEndDate(dateEnd)

    setTimeout(() => {
      setComparisonTimeRange({
        from: date.toISOString(),
        to: dateEnd.toISOString(),
      })
    }, 0)
  }

  const buttonLabel =
    startDate && endDate
      ? getFriendlyRangeLabel({
          from: startDate,
          to: endDate,
        })
      : "Pick a comparison start date"

  return (
    <div className="relative">
      <TimestampSelector
        onApplyCallback={handleDateChange}
        currentValue={startDate}
        button={<ComparisonInputButton label={buttonLabel} />}
        header={
          <div className="p-3 border-b border-gray-200 space-y-2">
            <div className="text-sm">Pick a comparison start date</div>
            <div className="text-xs text-gray-700 font-normal pr-4">
              We&apos;ll match the duration of your original selection, starting from this date.
            </div>
          </div>
        }
      />
    </div>
  )
}

const ComparisonInputButton = React.forwardRef(({ label, onClick }, _ref) => {
  return (
    <Button onClick={onClick} color="white">
      {label}
    </Button>
  )
})

ComparisonInputButton.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
}

ComparisonInputButton.displayName = "ComparisonInputButton"
