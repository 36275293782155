import Link from "#Root/components/shared/link"
import UrlHelper from "#Root/components/shared/url_helper"

// This component has a particular behaviour.
// The `incident` object may contain the organization slug and the app id.
// If that's the case, let's use it to build the link.
// Otherwise, let's fallback to the default behaviour, where the existing URL params will be used.
export const IncidentNumber = ({ incident, className, forceRefresh }) => {
  const organizationSlug = incident?.app?.organization?.slug
  const appId = incident?.app?.id

  const linkProps = {
    number: incident.number,
  }

  if (organizationSlug && appId) {
    linkProps.organizationSlug = organizationSlug
    linkProps.appId = appId
  }

  let href
  switch (incident.__typename) {
    case "ExceptionIncident":
      href = UrlHelper.exceptionIncidentPagePath(linkProps)
      break
    case "PerformanceIncident":
      href = UrlHelper.performanceIncidentPagePath(linkProps)
      break
    case "LogIncident":
      href = UrlHelper.logsIncidentPagePath(linkProps)
      break
    case "AnomalyIncident":
      href = UrlHelper.anomalyIncidentPagePath(linkProps)
      break
  }

  const linkClassName = "text-gray-600 no-underline " + className || "pl-2"

  return forceRefresh ? (
    <a href={href} title={`#${incident.number}`} className={linkClassName}>
      #{incident.number}
    </a>
  ) : (
    <Link href={href} title={`#${incident.number}`} className={linkClassName} />
  )
}

IncidentNumber.propTypes = {
  incident: PropTypes.object.isRequired,
  className: PropTypes.string,
  forceRefresh: PropTypes.bool,
}

export default IncidentNumber
