import { useRestClientContext } from "#Root/contexts/RestClientContext"
import { useRouter } from "#Root/hooks"
import { useQuery } from "#Root/utils/react-query-client"

const formatQueries = (queries) =>
  queries.map((query) => ({
    name: query.name,
    tags: query.tags,
    field: query.field,
    draw_null_as_zero: query.drawNullAsZero ?? true,
  }))

export const useFetchMetricsByTimeframe = ({
  queries,
  from: providedFrom,
  to: providedTo,
  enabled = true,
}) => {
  const restApiClient = useRestClientContext()
  const { getParams } = useRouter()
  const { appId } = getParams()

  const from = providedFrom
  const to = providedTo

  const variables = {
    site_id: appId,
    from,
    to,
    select: formatQueries(queries),
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ["metrics", variables],
    queryFn: () => restApiClient.metrics.timeseries(variables),
    enabled,
  })

  let timeseries = null

  if (data) {
    const series = data.series.map((serie) => ({
      ...serie,
      yMax: serie.max,
      yMin: serie.min,
      data: serie.data.map((point) => ({
        x: new Date(point.timestamp),
        y: point.value,
      })),
    }))

    const xMin = new Date(data.from)
    const xMax = new Date(data.to)

    timeseries = { ...data, series, xMin, xMax }
  }

  return {
    timeseries,
    loading: isLoading,
    error,
  }
}

useFetchMetricsByTimeframe.propTypes = {
  queries: PropTypes.array.isRequired,
  resolution: PropTypes.string,
  pollInterval: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
  timeframe: PropTypes.string,
}
