import Cookie from "universal-cookie"

import { SORT_DESC, SORT_VALUE } from "../constants"

const cookie = new Cookie()

export const DEFAULT_CHART_HOVER_SETTINGS = {
  mouseX: 0,
  mouseY: 0,
  invertDate: null,
  dataIndex: null,
  canvasWidth: 0,
  sortBy: SORT_VALUE,
  sortDirection: SORT_DESC,
  initialScrollOffset: 0,
}

const DEFAULT_CHART_LOCK_SETTINGS = {
  locked: false,
  graphLockingUsed: cookie.get("graph-locking-used") || false,
}

export const ChartHoverStateContext = React.createContext(DEFAULT_CHART_HOVER_SETTINGS)

export const ChartHoverStateProvider = ({ children, onItemHover, lockFeatureEnabled, ...rest }) => {
  const [hover, _setHoverState] = React.useState(DEFAULT_CHART_HOVER_SETTINGS)
  const [lockState, _setLockState] = React.useState(DEFAULT_CHART_LOCK_SETTINGS)

  const hoverRef = React.useRef()
  const lockRef = React.useRef()

  const toggleLock = React.useCallback(() => {
    if (!lockFeatureEnabled) return

    // set a cookie that the feature was used
    cookie.set("graph-locking-used", true, { maxAge: 356 * 86400, path: "/" })

    _setLockState((prev) => {
      if (prev.locked) {
        return { ...prev, locked: false }
      }
      return { locked: true, graphLockingUsed: true }
    })
  }, [lockFeatureEnabled])

  const unlock = React.useCallback(() => {
    _setLockState((prev) => {
      return { ...prev, locked: false }
    })
  }, [])

  const setHoverState = React.useCallback(
    (state) => {
      if (lockState.locked && lockFeatureEnabled) return
      onItemHover(state)

      const nextState = state || {}
      _setHoverState((prev) => ({
        ...nextState,
        sortBy: prev.sortBy,
        sortDirection: prev.sortDirection,
        initialScrollOffset: nextState.initialScrollOffset || 0,
      }))
    },
    [lockState.locked, lockFeatureEnabled],
  )

  const setHoverSort = React.useCallback(({ sortBy, sortDirection }) => {
    _setHoverState((prev) => ({
      ...prev,
      sortBy,
      sortDirection,
    }))
  }, [])

  // Update the hover position when the user scrolls. Doesn't update any other metadata.
  const updateHoverPosition = React.useCallback(
    (currentScrollOffset) => {
      if (lockState.locked) {
        _setHoverState((prevState) => ({
          ...prevState,
          mouseY: prevState.mouseY - (currentScrollOffset - prevState.initialScrollOffset),
        }))
      }
    },
    [lockState.locked],
  )

  return (
    <ChartHoverStateContext.Provider
      value={{
        ...hover,
        setHoverState,
        setHoverSort,
        updateHoverPosition,
        hoverRef,
        lockState,
        unlock,
        toggleLock,
        lockRef,
        ...rest,
      }}
    >
      {children}
    </ChartHoverStateContext.Provider>
  )
}

export const useChartHoverStateContext = () => React.useContext(ChartHoverStateContext)

ChartHoverStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onItemHover: PropTypes.func.isRequired,
  lockFeatureEnabled: PropTypes.bool,
}
