import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import { findTagValue } from "../../../../utils/metric_keys"
import Link from "../../../shared/link"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostNetworkTraffic = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const tags = alertTags || trigger.tags
  const { comparisonOperator, value } = trigger.thresholdCondition
  let label = "Host network traffic"
  if (tags) {
    const iface = findTagValue(tags, "interface")
    if (iface !== "*") {
      label += ` for "${iface}"`
    }
  }
  label = labelWithHostnameFromTags(label, tags)

  return (
    <>
      <div className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </div>
      <p className="pt-px text-gray-700">
        {trigger.metricName === "network_received" ? "Received" : "Transmitted"}/min
        <FormattedComparisonOperator value={comparisonOperator} />
        <FormattedFileSize value={value} />
      </p>
    </>
  )
}
TriggerHostNetworkTraffic.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostNetworkTraffic
