import { useDisabledLines } from "#Root/ui/ASChart/utils/hooks"
import { formatSerieLabels } from "#Root/ui/ASChart/utils/util"
import { noop } from "#Root/utils/noop"

export const BaseTimeseriesContext = React.createContext()
export const ComparisonTimeseriesContext = React.createContext()

const partitionSeries = (series, disabledLines) =>
  series.reduce(
    ([acc, disabled], serie) => {
      if (disabledLines.includes(serie.id)) {
        return [acc, [...disabled, serie]]
      }
      return [[...acc, serie], disabled]
    },
    [[], []],
  )

const createTimeseriesContextProvider = (Context, defaultValue) => {
  const Provider = ({ children, timeseries = {}, label }) => {
    const { disabledLines, toggleSelectedLine, enableAllLines } = useDisabledLines(
      (timeseries?.series ?? []).map((s) => s.id),
      [],
    )

    if (!timeseries?.series || timeseries.series.length === 0) {
      return <Context.Provider value={defaultValue}>{children}</Context.Provider>
    }

    const formattedSeries = formatSerieLabels({ series: timeseries.series, label })
    const [visibleSeries, disabledSeries] = partitionSeries(formattedSeries, disabledLines)

    return (
      <Context.Provider
        value={{
          disabledLines,
          toggleSelectedLine,
          enableAllLines,
          timeseries: { ...timeseries, series: formattedSeries },
          visibleSeries,
          disabledSeries,
          isComparisonPresent: true,
        }}
      >
        {children}
      </Context.Provider>
    )
  }

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
    timeseries: PropTypes.object.isRequired,
    label: PropTypes.string,
    isComparison: PropTypes.bool,
  }

  return Provider
}

export const BaseTimeseriesContextProvider = createTimeseriesContextProvider(
  BaseTimeseriesContext,
  {},
)
export const ComparisonTimeseriesContextProvider = createTimeseriesContextProvider(
  ComparisonTimeseriesContext,
  {
    disabledLines: [],
    toggleSelectedLine: noop,
    enableAllLines: noop,
    timeseries: { series: [] },
    visibleSeries: [],
    disabledSeries: [],
    isComparisonPresent: false,
  },
)

export const useBaseTimeseriesContext = () => React.useContext(BaseTimeseriesContext)
export const useComparisonTimeseriesContext = () => React.useContext(ComparisonTimeseriesContext)
