import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"

import { Y_AXIS_WIDTH } from "../constants"
import { useChartDataContext } from "../contexts/DataContext"
import { useChartFormattingContext } from "../contexts/FormattingContext"
import { useChartGraphContext } from "../contexts/GraphContext"
import FormattedLabel from "./FormattedLabel"

// This component has zero knowledge about anything context related.
// It's the core logic that places the y-axis on the chart.
// It's a pure component that can be using in any chart scenario
export const YAxisCore = ({
  canvasHeight,
  canvasWidth,
  gridLinesTop = 0,
  resolution,
  tickCount = 4,
  top,
  valueFormat,
  valueInput,
  valuePrecision,
  yAxisWidth,
  yDomain,
  yScale,
  showGridLines = true,
}) => {
  yScale.domain(yDomain)
  const ticks = yScale.ticks.apply(yScale, [tickCount])

  return (
    <>
      {showGridLines &&
        ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute h-px w-px z-0 pointer-events-none border-t border-gray-200/75 border-dashed y-axis-grid-line"
            style={{ top: yScale(tick) + gridLinesTop, width: canvasWidth, left: yAxisWidth }}
          />
        ))}
      <div
        className="absolute top-0 left-0 text-ms text-gray-600"
        style={{
          top: top,
          width: yAxisWidth,
          height: canvasHeight,
        }}
      >
        {ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute left transform -translate-y-1/2 select-none"
            style={{ top: `${yScale(tick)}px`, width: yAxisWidth }}
          >
            <FormattedLabel
              value={tick}
              valueFormat={valueFormat}
              resolution={resolution}
              valuePrecision={valuePrecision}
              valueInput={valueInput}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const YAxis = ({ tickCount = 4 }) => {
  const { valuePrecision, valueInput, valueFormat } = useChartFormattingContext()
  const { yScale, canvasHeight, canvasWidth, domain } = useChartGraphContext()
  const {
    timeseries: { resolution },
  } = useChartDataContext()
  const { settings } = useChartSettingsContext()

  return (
    <YAxisCore
      canvasHeight={canvasHeight}
      canvasWidth={canvasWidth}
      resolution={resolution}
      tickCount={tickCount}
      top={0}
      valueFormat={valueFormat}
      valueInput={valueInput}
      valuePrecision={valuePrecision}
      yAxisWidth={Y_AXIS_WIDTH}
      yDomain={domain.y}
      yScale={yScale}
      showGridLines={settings.horizontalSupportLines}
    />
  )
}

YAxis.propTypes = {
  tickCount: PropTypes.number,
}

YAxisCore.propTypes = {
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  gridLinesTop: PropTypes.number,
  resolution: PropTypes.string,
  tickCount: PropTypes.number,
  top: PropTypes.number,
  valueFormat: PropTypes.string,
  valueInput: PropTypes.string,
  valuePrecision: PropTypes.number,
  yAxisWidth: PropTypes.number,
  yDomain: PropTypes.array,
  yScale: PropTypes.func,
  showGridLines: PropTypes.bool,
}

YAxis.displayName = "YAxis"

export default YAxis
