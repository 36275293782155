import { useContext } from "react"

import { RouterContext } from "#Root/contexts/RouterContext"

export const useRouter = () => {
  const router = useContext(RouterContext)

  const navigateWithParams = (params) => {
    router.navigate(currentPathWithParams(params), { trigger: true })
  }

  const currentPathWithParams = (givenParams) => {
    const urlParams = new URLSearchParams(location.search)
    // Loop the params if present, add to query
    // otherwise remove it from the query if it's present
    for (const key in givenParams) {
      const val = givenParams[key]
      urlParams.delete(key)
      if (val != null) {
        urlParams.append(key, val)
      }
    }

    // Join the path with params
    return `${location.pathname}?${urlParams.toString()}`
  }

  const currentUrlWithParams = (params) => {
    return `${window.origin}${currentPathWithParams(params)}`
  }

  const getParams = () => {
    return window._routerParams
  }

  const sanitizedPath = (path) => {
    const offset = path.endsWith("/?")
      ? -2
      : path.endsWith("?") || path.endsWith("/")
        ? -1
        : path.length
    return path.slice(0, offset)
  }

  const removeQueryParamsFromPath = (path) => {
    const paramIndex = path.indexOf("?")
    if (paramIndex !== -1) {
      return path.substring(0, paramIndex)
    }
    return path
  }

  return {
    router,
    navigateWithParams,
    currentPathWithParams,
    currentUrlWithParams,
    getParams,
    sanitizedPath,
    removeQueryParamsFromPath,
  }
}

export default useRouter
