import gql from "graphql-tag"

export default gql`
  fragment Logbook on Logbook {
    id
    pinnedNoteId
    items {
      ... on Note {
        id
        content
        createdAt
        updatedAt
        viewerCanDelete
        author {
          id
          name
        }
      }
      ... on StateChange {
        id
        createdAt
        updatedAt
        previousState
        currentState
        author {
          id
          name
        }
      }
      ... on ManualAlertStateChange {
        id
        createdAt
        updatedAt
        previousState
        currentState
        author {
          id
          name
        }
      }
      ... on SeverityChange {
        id
        createdAt
        updatedAt
        previousSeverity
        currentSeverity
        author {
          id
          name
        }
      }
      ... on NotificationFrequencyChange {
        id
        createdAt
        updatedAt
        previousFrequency
        currentFrequency
        author {
          id
          name
        }
      }
      ... on NotificationThresholdChange {
        id
        createdAt
        updatedAt
        previousThreshold
        currentThreshold
        author {
          id
          name
        }
      }
      ... on AssigneeChange {
        id
        createdAt
        updatedAt
        addedAssignees {
          id
          name
        }
        removedAssignees {
          id
          name
        }
        author {
          id
          name
        }
      }
      ... on NotifierResult {
        id
        createdAt
        updatedAt
        results {
          successCount
          successLast
          errorCount
          errorLast
          notifier {
            id
            name
            icon
          }
        }
      }
      ... on IntegrationCreate {
        id
        createdAt
        updatedAt
        name
        url
        author {
          id
          name
        }
      }
    }
  }
`
