import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedNumber from "#Root/formatters/FormattedNumber"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import Link from "../../../shared/link"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostLoadAverage = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const tags = alertTags || trigger.tags
  const { comparisonOperator, value } = trigger.thresholdCondition
  const label = labelWithHostnameFromTags("Host load average", tags)

  return (
    <>
      <div className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </div>
      <p className="pt-px text-gray-700">
        Load average
        <FormattedComparisonOperator value={comparisonOperator} />
        <FormattedNumber value={value} />
      </p>
    </>
  )
}
TriggerHostLoadAverage.propTypes = {
  trigger: PropTypes.object.isRequired,
  link: PropTypes.string,
  tags: PropTypes.array,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostLoadAverage
