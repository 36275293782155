import LoadingSpinner from "#Components/shared/loading_spinner"
import { SELECT_SINGLE_NAMESPACE_SHORTCUT_KEY } from "#Root/constants/KeyboardShortcuts"
import Dropdown from "#Root/ui/Dropdown"
import KeyboardShortcut from "#Root/ui/KeyboardShortcut/KeyboardShortcut"

import withAppInfo from "../../../wrappers/app_info"

export const NamespaceFilter = ({ onChange, selected, appInfo }) => {
  const [selectedNamespaces, setSelectedNamespaces] = React.useState(selected)

  React.useEffect(() => {
    if (
      appInfo &&
      appInfo.namespaces &&
      appInfo.namespaces.length > 0 &&
      selectedNamespaces.length === 0
    ) {
      setSelectedNamespaces(appInfo.namespaces.slice())
    }
  }, [appInfo, selectedNamespaces.length])

  const handleFilterChange = (option, metaKeyPressed) => {
    if (selectedNamespaces.length === 1 && selectedNamespaces[0] === option && !metaKeyPressed)
      return

    let newNamespaces = []
    if (metaKeyPressed && selectedNamespaces.length === 1) {
      newNamespaces = appInfo.namespaces
    } else if (metaKeyPressed) {
      newNamespaces = [option]
    } else if (selectedNamespaces.includes(option)) {
      newNamespaces = selectedNamespaces.filter((namespace) => namespace !== option)
    } else {
      newNamespaces = selectedNamespaces.concat(option)
    }
    setSelectedNamespaces(newNamespaces)
    onChange(newNamespaces)
  }

  const label = () => {
    if (!appInfo || !appInfo?.namespaces) {
      return <LoadingSpinner />
    }

    if (selectedNamespaces.length === 1) {
      return selectedNamespaces[0]
    } else if (selectedNamespaces.length === appInfo.namespaces.length) {
      return "All namespaces"
    } else {
      return `${selectedNamespaces.length} namespaces`
    }
  }

  return (
    <Dropdown select="multiple" closeOnSelect={false}>
      <Dropdown.Button>{label()}</Dropdown.Button>
      {appInfo?.namespaces && (
        <Dropdown.Items align="left">
          {appInfo.namespaces.length > 4 && (
            <div className="c-dropdown__title">
              <p className="text-gray-700">
                <KeyboardShortcut shortcut={SELECT_SINGLE_NAMESPACE_SHORTCUT_KEY} /> to select a
                single namespace.
              </p>
            </div>
          )}
          {appInfo.namespaces.sort().map((namespace) => (
            <Dropdown.Item
              key={namespace}
              value={namespace}
              onSelect={handleFilterChange}
              selected={selectedNamespaces.includes(namespace)}
            >
              <p data-testid="namespace">{namespace}</p>
            </Dropdown.Item>
          ))}
        </Dropdown.Items>
      )}
    </Dropdown>
  )
}

NamespaceFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array,
  appInfo: PropTypes.object,
}

export default withAppInfo(NamespaceFilter)
