import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import { usePaginatedMetricsListQuery } from "#Root/hooks/usePaginatedQuery"
import { Table } from "#Root/ui"
import { DurationCell, NumberCell, PercentageCell } from "#Root/ui/Table/Cells/Cells"
import { columnHelper } from "#Root/ui/Table/Table"
import { aggregateMetricListToObjects } from "#Root/utils/metric_list_aggregator"
import { maxField } from "#Root/utils/table"

const paramsToQueryVariables = (params, namespaces) => {
  const query = []
  for (const namespace of namespaces) {
    query.push({
      name: "transaction_duration",
      tags: [
        { key: "hostname", value: "*" },
        { key: "namespace", value: namespace },
      ],
      fields: [
        { field: "COUNT", aggregate: "SUM" },
        { field: "P90", aggregate: "AVG" },
        { field: "MEAN", aggregate: "AVG" },
      ],
    })

    query.push({
      name: "transaction_exception_rate",
      tags: [
        { key: "hostname", value: "*" },
        { key: "namespace", value: namespace },
      ],
      fields: [{ field: "GAUGE", aggregate: "AVG" }],
    })
  }
  return {
    appId: params.appId,
    start: params.td_start,
    end: params.td_end,
    query: query,
  }
}

const TimeDetectiveHostsPage = ({ params, namespaces }) => {
  const { loading, error, data } = usePaginatedMetricsListQuery(
    paramsToQueryVariables(params, namespaces),
  )
  const map = {
    transaction_duration: { count: "throughput", p90: "p90", mean: "mean" },
    transaction_exception_rate: { gauge: "exRate" },
  }
  const hosts = aggregateMetricListToObjects(data?.app?.metrics?.list?.rows || [], map)
  const maxValues = {
    mean: maxField(hosts, "mean"),
    p90: maxField(hosts, "p90"),
    throughput: maxField(hosts, "throughput"),
    exRate: maxField(hosts, "exRate"),
  }

  const columns = [
    columnHelper.accessor("hostname", {
      header: "Name",
      cell: ({ row }) => {
        const { namespace, hostname } = row.original

        return (
          <div className="max-w-fit flex flex-col">
            <Link
              className="text-blue truncate"
              href={UrlHelper.hostUsageInspectPath({ hostnames: `${namespace}:${hostname}` }, [
                "namespaces",
              ])}
              title={hostname}
            />
            <NamespaceLabel className="max-w-fit mt-1" namespace={namespace} />
          </div>
        )
      },
    }),
    columnHelper.accessor("mean", {
      header: "Mean",
      cell: ({ getValue, table, column }) => (
        <DurationCell
          value={getValue()}
          maxValues={table.options.meta.maxValues}
          columnId={column.id}
        />
      ),
    }),
    columnHelper.accessor("p90", {
      header: "Percentile",
      cell: ({ getValue, table, column }) => (
        <DurationCell
          value={getValue()}
          maxValues={table.options.meta.maxValues}
          columnId={column.id}
        />
      ),
    }),
    columnHelper.accessor("throughput", {
      header: "Throughput",
      cell: ({ getValue, table, column }) => (
        <NumberCell
          value={getValue()}
          maxValues={table.options.meta.maxValues}
          columnId={column.id}
        />
      ),
    }),
    columnHelper.accessor("exRate", {
      header: "Error rate",
      cell: ({ getValue, table, column }) => (
        <PercentageCell
          value={getValue()}
          maxValues={table.options.meta.maxValues}
          columnId={column.id}
        />
      ),
    }),
  ]

  return (
    <Table
      columns={columns}
      data={hosts}
      header={<Table.Header title="Host usage in this timeframe" />}
      defaultSorting={{ id: "throughput", desc: true }}
      title="Host usage in this timeframe"
      loading={loading}
      error={error}
      metaData={{ maxValues }}
      fixed
    >
      <Table.Headers />
      <Table.Loading />
      <Table.Errors />
      <Table.Empty />
      <Table.Rows />
    </Table>
  )
}

TimeDetectiveHostsPage.propTypes = {
  params: PropTypes.object.isRequired,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectiveHostsPage
