import { useTableContext } from "#Root/contexts/TableContext"

const ErrorState = ({ children }) => {
  const { table, error } = useTableContext()
  if (!error) return null
  return (
    <tbody className="empty-table">
      <tr>
        <td colSpan={table.getFlatHeaders().length} className="c-table__td">
          <div className="flex flex-col w-full h-full justify-center items-center">
            {children !== undefined && children}
            {children === undefined && (
              <>
                <i className="text-2xl mb-4 text-gray-600 fa fa-exclamation-triangle" />
                <h2 className="c-heading--base">Something is wrong</h2>
                <p className="text-gray-700 text-center">
                  We were unable to load this table. Please try again or contact our support if the
                  problem persists.
                </p>
              </>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  )
}

ErrorState.propTypes = {
  children: PropTypes.node,
}

export default ErrorState
