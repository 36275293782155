import { scaleLinear, scaleUtc } from "d3"

import { colorPaletteMap } from "#Root/contexts/ChartSettingsContext"
import {
  applyColorPalette,
  getExtents,
  significantDecimalCountFor,
} from "#Root/ui/ASChart/utils/util"

export const getChartSeriesMetadata = ({
  timeseries,
  disabledLines,
  canvasHeight,
  canvasWidth,
  settings,
}) => {
  const { xMin, xMax, yMin, yMax } = getExtents({
    timeseries,
    disabledLines,
    isStacked: false,
    isRelative: false,
  })

  const valueDifference = yMax - yMin
  const valuePrecision = significantDecimalCountFor(valueDifference)

  const yDomain = [yMin, yMax]
  const yScale = scaleLinear()
    .range([canvasHeight - 1, 5])
    .domain(yDomain)

  const xDomain = [new Date(xMin), new Date(xMax)]

  const range = [0, canvasWidth]
  const xScale = scaleUtc().range(range).domain(xDomain)

  const colorPalette = colorPaletteMap[settings.colorPalette]
  const colors = applyColorPalette(timeseries.series, colorPalette, "label")

  return {
    xMin,
    xMax,
    yMin,
    yMax,
    valueDifference,
    valuePrecision,
    yDomain,
    yScale,
    xDomain,
    xScale,
    colors,
  }
}

export const getCombinedSeriesMetadata = ({
  baseYMax,
  baseYMin,
  comparisonYMax,
  comparisonYMin,
  canvasHeight,
}) => {
  const realYMax = Math.max(baseYMax, comparisonYMax)
  const realYMin = Math.min(baseYMin, comparisonYMin)

  const valueDifference = realYMax - realYMin
  const valuePrecision = significantDecimalCountFor(valueDifference)

  const yDomain = [realYMin, realYMax]
  const yScale = scaleLinear()
    .range([canvasHeight - 1, 5])
    .domain(yDomain)

  return {
    realYMax,
    realYMin,
    valueDifference,
    valuePrecision,
    yDomain,
    yScale,
  }
}
