import TriggerFormComparisonOperators, {
  COMPARISON_OPERATORS,
} from "#Components/triggers/form/fields/ComparisonOperators"
import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"
import { TRIGGER_TYPES } from "#Root/constants/Triggers"

import { findTagValue } from "../../../../utils/metric_keys"
import withNotifiers, { notifiersPropType } from "../../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../../wrappers/warmup_and_cooldown"
import TriggerGraphPreview from "../../GraphPreview"
import ShareLink from "../ShareLink"
import TriggerFormHostname from "./Hostname"

const formName = "HostCPUUsage"
const STATES = ["total_usage", "nice", "idle", "steal", "system", "iowait", "user"]
export class TriggerFormHostCPUUsage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    blankSlate: PropTypes.bool,
    trigger: PropTypes.object,
    appId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    notifiers: notifiersPropType.isRequired,
    warmupAndCooldown: warmupAndCooldownPropType.isRequired,
    error: PropTypes.object,
  }
  static form = formName
  static params = {
    hostname: "tag",
    state: "tag",
    comparisonOperator: "field",
    conditionValue: "field",
  }

  constructor(props, context) {
    super(props, context)
    const { trigger, params } = props
    // Use data from existing trigger
    if (trigger && trigger.id) {
      const tags = trigger.tags
      this.state = {
        comparisonOperator: trigger.thresholdCondition.comparisonOperator,
        conditionValue: trigger.thresholdCondition.value,
        hostname: findTagValue(tags, "hostname"),
        state: findTagValue(tags, "state"),
        description: trigger.description,
      }
      return
    } else {
      this.state = {
        comparisonOperator: params.comparisonOperator || COMPARISON_OPERATORS[0]["value"],
        conditionValue: params.conditionValue || 100,
        hostname: params.hostname || "*",
        state: params.state || "total_usage",
        description: params.description || "",
      }
      return
    }
  }

  triggerObject() {
    const { hostname, state } = this.state
    return {
      metricName: "cpu",
      field: "GAUGE",
      tags: [
        { key: "host_metric", value: "" },
        { key: "hostname", value: hostname },
        { key: "state", value: state },
      ],
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { comparisonOperator, conditionValue, description } = this.state
    this.props.onChange({
      ...this.triggerObject(),
      comparisonOperator,
      conditionValue: parseFloat(conditionValue),
      description,
    })
  }

  render() {
    let cancelLink
    let saveButtonLabel = "Save Trigger"
    const { appId, error, onClose, notifiers, warmupAndCooldown } = this.props

    if (this.props.blankSlate) {
      saveButtonLabel = "Save your first Trigger"
    } else {
      cancelLink = (
        <button id="cancelLink" onClick={onClose} className="c-button c-button--sm c-button--white">
          Cancel
        </button>
      )
    }
    const { hostname } = this.state

    return (
      <>
        <div className="relative h-full overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
            <h2 className="c-heading text-base mb-0 text-gray-800">Host CPU usage Trigger</h2>
          </div>
          {error}
          <form onSubmit={this.handleFormSubmit} acceptCharset="UTF-8" className="px-5">
            <p className="py-5">
              Receive alerts when your CPU is spending more time on a certain process execution
              level. &quot;User&quot; processes, &quot;system&quot; processes or if it&apos;s more
              &quot;idle&quot; than active.
            </p>
            <hr />

            <div className="c-form">
              <label className="c-form__label" htmlFor="state">
                When
              </label>
              <div className="c-form__element">
                <div className="c-select">
                  <select
                    onChange={(e) => this.setState({ state: e.target.value })}
                    id="state"
                    name="state"
                    value={this.state.state}
                    className="c-select__input required"
                  >
                    {STATES.map((state) => (
                      <option key={"state-" + state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="c-form">
              <label className="c-form__label">Threshold is</label>
              <div className="c-form__element flex">
                <div className="w-1/2 mr-2">
                  <TriggerFormComparisonOperators
                    onChange={(e) => this.setState({ comparisonOperator: e.comparisonOperator })}
                    value={this.state.comparisonOperator}
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <div className="c-textfield">
                    <input
                      className="c-textfield__input"
                      name="conditionValue"
                      type="number"
                      value={this.state.conditionValue}
                      min="0"
                      aria-label="Threshold value"
                      onChange={(e) => this.setState({ conditionValue: e.target.value })}
                    />
                    <span className="c-textfield__suffix">%</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <TriggerFormHostname
              appId={appId}
              hostname={hostname}
              onChange={(newHostname) => this.setState({ hostname: newHostname })}
            />
            <hr />

            <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
            <hr />

            <TriggerFormDescription
              description={this.state.description}
              onChange={(newDescription) => this.setState({ description: newDescription })}
            />
            <hr />

            <TriggerFormNotifiers notifiers={notifiers} />
            <hr />

            <ShareLink trigger={this.state} form={formName} warmupAndCooldown={warmupAndCooldown} />

            <div className="my-5">
              <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
              {cancelLink}
            </div>
          </form>
        </div>
        <div className="w-256 p-5 bg-gray-100">
          <TriggerGraphPreview
            title="Host CPU usage graph preview"
            lineLabel={TRIGGER_TYPES["HostCPUUsage"].label}
            valueFormat={TRIGGER_TYPES["HostCPUUsage"].formatter}
            appId={appId}
            trigger={this.triggerObject()}
          />
        </div>
      </>
    )
  }
}

export default withWarmupAndCooldown(withNotifiers(TriggerFormHostCPUUsage))
