import { useQuery } from "@apollo/react-hooks"
import dayjs from "dayjs"
import gql from "graphql-tag"

import TimestampSelector from "#Root/components/filters/DateTimeFilter/TimestampSelector"
import NamespaceFilter from "#Root/components/filters/NamespaceFilter"
import AlertsPage from "#Root/components/TimeDetectiveOverlay/Alerts"
import ErrorsPage from "#Root/components/TimeDetectiveOverlay/Errors"
import HostsPage from "#Root/components/TimeDetectiveOverlay/Hosts"
import LogsPage from "#Root/components/TimeDetectiveOverlay/Logs"
import OutsideTTL from "#Root/components/TimeDetectiveOverlay/OutsideTtl"
import PerformancePage from "#Root/components/TimeDetectiveOverlay/Performance"
import { useRouter } from "#Root/hooks"
import Button from "#Root/ui/Button"
import Overlay from "#Root/ui/Overlay"
import { parseTimestampFromURL } from "#Root/utils/datetime"
import { arrayToParam, paramToArray } from "#Root/utils/params"
import { nextRange, previousRange, resolution, timeRange } from "#Root/utils/TimeDetective"

import Tabs from "./Tabs"

const QUERY = gql`
  query TimeDetectiveQuery($appId: String!) {
    app(id: $appId) {
      id
      namespaces
      organization {
        id
        ttl
      }
    }
  }
`

const TimeDetectiveOverlay = ({ params }) => {
  const { loading, error, data } = useQuery(QUERY, { variables: { appId: params.appId } })
  const { navigateWithParams } = useRouter()
  const resolutionText = resolution(params.td_start)
  let withNamespaceFilter = true
  let component

  const handleClose = () => {
    navigateWithParams({
      overlay: null,
      td_start: null,
      td_end: null,
      td_namespaces: null,
      td_tab: null,
    })
  }

  const setParams = (params) => {
    navigateWithParams({ ...params })
  }

  const handleNamespaceFilterChange = (newFilter) => {
    if (newFilter.length == 0) {
      newFilter.push("all")
    }
    navigateWithParams({ td_namespaces: arrayToParam(newFilter) })
  }

  let namespaces = []
  if (params.td_namespaces && params.td_namespaces != "all") {
    namespaces = paramToArray(params.td_namespaces)
  } else if (params.namespaces) {
    namespaces = paramToArray(params.namespaces)
  }

  if (!loading && !error && data?.app) {
    const ttlTime = dayjs().subtract(data.app.organization.ttl, "second")

    if (parseTimestampFromURL(params.td_start) < ttlTime) {
      component = (
        <div className="c-box">
          <OutsideTTL ttlTime={ttlTime} />
        </div>
      )
    }
  }

  if (!component) {
    const namespacesOrWildcard = namespaces.length > 0 ? namespaces : ["*"]
    switch (params.td_tab) {
      case "hosts":
        component = <HostsPage params={params} namespaces={namespacesOrWildcard} />
        break
      case "errors":
        component = <ErrorsPage params={params} namespaces={namespacesOrWildcard} />
        break
      case "alerts":
        withNamespaceFilter = false
        component = <AlertsPage params={params} />
        break
      case "logs":
        withNamespaceFilter = false
        component = <LogsPage params={params} />
        break
      default:
        component = <PerformancePage params={params} namespaces={namespacesOrWildcard} />
        break
    }
  }

  return (
    <Overlay open={true} onClose={handleClose} size="full">
      <Overlay.Title title="Time Detective" />
      <Overlay.Content background="gray" noSpacing>
        <div className="flex items-stretch">
          <Tabs />
          <div className="grow">
            <div className="m-5 flex gap-[8px]">
              {withNamespaceFilter && (
                <NamespaceFilter
                  onChange={handleNamespaceFilterChange}
                  params={params}
                  selected={namespaces}
                />
              )}

              <Button
                className="ml-auto"
                color="white"
                size="xs"
                onClick={() => {
                  const { start, end } = previousRange(params.td_start)
                  setParams({
                    td_start: start.toISOString(),
                    td_end: end.toISOString(),
                  })
                }}
              >
                <i className="far fa-arrow-left" />1 {resolutionText}
              </Button>
              <div>
                {/*
                  TimestampSelector adds an element to the dom,
                  combined with the `gap` it jumps the datepicker a couple of pixels to the left.
                  This wrapping div prevents this behavior
                */}
                <TimestampSelector
                  currentValue={params.td_start}
                  fullRoundedBorders={true}
                  segmentedControl={false}
                  customButtonIcon={<i className="fa fa-calendar text-gray-500 !ml-2" />}
                  onApplyCallback={(newTimestamp) => {
                    const { start, end } = timeRange(newTimestamp)
                    setParams({
                      td_start: start.toISOString(),
                      td_end: end.toISOString(),
                    })
                  }}
                />
              </div>
              <Button
                color="white"
                size="xs"
                onClick={() => {
                  const { start, end } = nextRange(params.td_start)
                  setParams({
                    td_start: start.toISOString(),
                    td_end: end.toISOString(),
                  })
                }}
              >
                1 {resolutionText}
                <i className="far fa-arrow-right" />
              </Button>
            </div>
            <div className="m-5 min-h-[60vh]">{component}</div>
          </div>
        </div>
      </Overlay.Content>
    </Overlay>
  )
}

TimeDetectiveOverlay.propTypes = {
  params: PropTypes.shape({
    td_start: PropTypes.string,
    td_end: PropTypes.string,
    td_namespaces: PropTypes.string,
    td_tab: PropTypes.string,
    namespaces: PropTypes.string,
    appId: PropTypes.string.isRequired,
  }).isRequired,
}

export default TimeDetectiveOverlay
