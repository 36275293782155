import BarChart from "#Components/shared/bar_chart"
import FormattedNumber from "#Root/formatters/FormattedNumber"

const FormattedPercentage = ({ value, precision }) => (
  <span>
    <FormattedNumber value={value} precision={precision} />
    <i>%</i>
  </span>
)

FormattedPercentage.propTypes = {
  value: PropTypes.number.isRequired,
  precision: PropTypes.number,
}

const PercentageCell = ({ columnId, value, maxValues, precision }) => {
  return (
    <div className="space-y-1">
      <FormattedPercentage value={value} precision={precision} />
      <BarChart max={maxValues[columnId]} val={value} />
    </div>
  )
}

PercentageCell.propTypes = {
  columnId: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValues: PropTypes.object.isRequired,
  precision: PropTypes.number,
}

export default PercentageCell
