import { curveMonotoneX, line } from "d3-shape"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import cn from "#Root/utils/cn"

import { HOVER_LOW_OPACITY } from "../../constants"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

// This component has zero knowledge about anything context related.
// It's the core logic that places the lines on the chart.
// It's a pure component that can be using in any chart scenario
export const LineCore = ({
  className,
  data,
  xScale,
  yScale,
  color,
  xDomain,
  yDomain,
  dotted,
  isActive,
}) => {
  const { settings } = useChartSettingsContext()

  xScale.domain(xDomain)
  yScale.domain(yDomain)

  const d3Line = line()
    .y((d) => yScale(d.y))
    .x((d) => xScale(d.x))
    .curve(curveMonotoneX)

  const path = d3Line(data)
  const style = {
    strokeWidth: settings.lineThickness,
    stroke: color,
    color: color,
    fill: "none",
    strokeDasharray: dotted ? "4 4" : "none",
    opacity: isActive ? 1 : HOVER_LOW_OPACITY,
  }

  return <path d={path} className={cn("line", className)} style={style} />
}

export const Line = ({ className, data, id, ...rest }) => {
  const { xScale, yScale, colors, domain } = useChartGraphContext()
  const { hoveredLine } = useChartHoverStateContext()

  const color = colors({ id, ...rest })
  const isActive = (hoveredLine && hoveredLine === id) || !hoveredLine

  return (
    <LineCore
      className={className}
      data={data}
      color={color}
      {...rest}
      xScale={xScale}
      yScale={yScale}
      xDomain={domain.x}
      yDomain={domain.y}
      isActive={isActive}
    />
  )
}

Line.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

LineCore.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  yDomain: PropTypes.array.isRequired,
  xDomain: PropTypes.array.isRequired,
  dotted: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default Line
