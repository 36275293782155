import { LockButton } from "#Root/ui/ASChart/components/HighlightLine/Lock"

const HighlightLine = ({
  invertDate,
  canvasHeight,
  canvasWidth,
  width,
  xScale,
  xDomain,
  top,
  locked,
  toggleLock,
}) => {
  if (!invertDate) {
    return null
  }

  xScale.domain(xDomain)

  const canvasXOffset = width - canvasWidth
  const dataPointStart = xScale(invertDate) + canvasXOffset

  const lockLeft = dataPointStart
  const lineStyle = {
    width: 1,
    left: dataPointStart,
    top: 0,
    height: canvasHeight,
    zIndex: 0,
  }

  return (
    <div>
      <div
        data-testid="chart-hover-highlight-line"
        style={lineStyle}
        className="pointer-events-none absolute cursor-pointer bg-gray-200/90"
      />
      <div
        data-testid="chart-hover-lock"
        className="absolute -translate-y-1/2 -translate-x-1/2"
        style={{ left: lockLeft, top: top }}
      >
        <LockButton locked={locked} onClick={toggleLock} />
      </div>
    </div>
  )
}

HighlightLine.propTypes = {
  invertDate: PropTypes.instanceOf(Date),
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  width: PropTypes.number,
  xScale: PropTypes.func,
  xDomain: PropTypes.func,
  top: PropTypes.number,
  locked: PropTypes.bool,
  toggleLock: PropTypes.func,
}

export default HighlightLine
