import { FormProvider, useForm, useFormContext, useFormState } from "react-hook-form"
export { useForm, useFormState, useWatch } from "react-hook-form"

import Button from "#Root/ui/Button"
import cn from "#Root/utils/cn"

import TextArea from "../TextArea"
import TextField from "../TextField"

export const Form = ({ onSubmit, formControl, children }) => {
  const inlineFormControl = useForm()
  if (!formControl) formControl = inlineFormControl
  return (
    <FormProvider {...formControl}>
      <form onSubmit={formControl.handleSubmit((data, event) => onSubmit(data, event))}>
        {children}
      </form>
    </FormProvider>
  )
}

Form.displayName = "Form"
Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formControl: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export const FormTextArea = ({ name, validation = {}, ...props }) => {
  const { register } = useFormContext()
  const { ...formAttributes } = register(name, { ...validation })

  return <TextArea id={name} {...props} {...formAttributes} />
}

FormTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
}

export const FormTextField = ({ name, validation = {}, onBlur, onChange, ...props }) => {
  const { register } = useFormContext()
  const { ...formAttributes } = register(name, { ...validation, onBlur, onChange })

  return <TextField id={name} {...props} {...formAttributes} />
}

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export const FormField = ({ className, children }) => {
  return <div className={`c-form ${className}`}>{children}</div>
}

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export const FormLabel = ({ name, children }) => {
  return (
    <label className="c-form__label ignore-old-css" htmlFor={name}>
      {children}
    </label>
  )
}

FormLabel.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export const FormHint = ({ children }) => {
  return <span className="c-form__hint">{children}</span>
}

FormHint.propTypes = {
  children: PropTypes.node.isRequired,
}

export const FormDescription = ({ children }) => {
  return <span className="c-form__description">{children}</span>
}

FormDescription.propTypes = {
  children: PropTypes.node.isRequired,
}

export const FormCheckBox = ({ name, label, ...props }) => {
  const { register } = useFormContext()
  const { ...formAttributes } = register(name)

  return (
    <div className="c-form__element">
      <div className="c-checkbox">
        <input
          className="c-checkbox__input"
          type="checkbox"
          id={name}
          name={name}
          {...props}
          {...formAttributes}
        />
        <label className="c-checkbox__label ignore-old-css font-semibold" htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  )
}

FormCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export const FormSelect = ({ name, options, onBlur, ...props }) => {
  const { register } = useFormContext()
  const { ...formAttributes } = register(name, { onBlur })

  return (
    <div className="c-form__element">
      <div className="c-select">
        <select id={name} name={name} className="c-select__input" {...props} {...formAttributes}>
          {options.map((option) => {
            return (
              <option key={`${option.key} ${option.value}`} value={option.key}>
                {option.value}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onBlur: PropTypes.func,
}

export const FormSubmitButton = ({ loading, loadingText = "Saving...", children }) => {
  const { control, trigger } = useFormContext()
  const { isValid, errors } = useFormState({ control })

  React.useEffect(() => {
    trigger()
  }, [errors, trigger])

  return (
    <Button
      type="submit"
      disabled={!isValid}
      loading={loading}
      loadingText={loadingText}
      className={cn("c-button c-button--xs ignore-old-css", {
        "c-button--disabled": !isValid,
      })}
    >
      {children}
    </Button>
  )
}

FormSubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
}

export const FormError = ({ children }) => {
  return <div className="c-form__error">{children}</div>
}

FormError.propTypes = {
  children: PropTypes.node.isRequired,
}

Form.Description = FormDescription
Form.Field = FormField
Form.Label = FormLabel
Form.Hint = FormHint
Form.CheckBox = FormCheckBox
Form.TextArea = FormTextArea
Form.TextField = FormTextField
Form.Select = FormSelect
Form.SubmitButton = FormSubmitButton
Form.Error = FormError

export default Form
