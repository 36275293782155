import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"

import ImpactPercent from "../../shared/impact_percent"
import Link from "../../shared/link"
import TriggerFormButton from "../../triggers/form_button"

const TriggerExceptionRate = ({ trigger, tags, link, appId, triggerId }) => {
  const { comparisonOperator, value } = trigger.thresholdCondition

  const namespace = (tags || trigger.tags).find((tag) => {
    return tag.key == "namespace"
  }).value
  const content = `${namespace} error rate`

  return (
    <>
      <div className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={content} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={content}
            className="text-blue"
          />
        )}
      </div>
      <p className="pt-px text-gray-700">
        Error rate
        <FormattedComparisonOperator value={comparisonOperator} />
        <ImpactPercent val={value} total={100} />
      </p>
    </>
  )
}
TriggerExceptionRate.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerExceptionRate
