import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { TableContext } from "#Root/contexts/TableContext"
import { useLocalStorage } from "#Root/hooks/useLocalStorage"
import ColumnSelect from "#Root/ui/Table/ColumnSelect"
import EmptyState from "#Root/ui/Table/EmptyState"
import ErrorState from "#Root/ui/Table/ErrorState"
import Header from "#Root/ui/Table/Header"
import Headers from "#Root/ui/Table/Headers"
import LoadingState from "#Root/ui/Table/LoadingState"
import Rows from "#Root/ui/Table/Rows"
import VirtualizedRows from "#Root/ui/Table/VirtualizedRows"
import { cn } from "#Root/utils/cn"

const columnHelper = createColumnHelper()

const CheckBox = ({ checked, onChange, indeterminate, disabled }) => {
  const ref = React.useRef(null)

  if (typeof indeterminate === "boolean" && ref.current) {
    ref.current.indeterminate = !checked && indeterminate
  }

  return (
    <input
      type="checkbox"
      className="ignore-old-css"
      ref={ref}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      data-testid="checkbox"
    />
  )
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
}

// Append `columns-visibility` and keep `localStorageKey` empty for future improvements
// Maybe we want to persist certain column-widths in the future.
function getColumnPersistenceKey(key) {
  return key ? `${key}-columns-visibility` : null
}

const Table = ({
  columns: defaultColumns,
  data,
  fetchMore,
  metaData = {},
  loading,
  error,
  fetchMoreLoading,
  fetchMoreCompleted,
  defaultSorting,
  header,
  fixed = false,
  children,
  rowSelection,
  setRowSelection,
  inline = false,
  boxClassName,
  localStorageKey,
  onManualSort,
  selectionLimit,
  ...rest
}) => {
  const bottom = React.useRef(null)

  const [persistedColumnVisibility, setPersistedColumnVisibility] = useLocalStorage(
    getColumnPersistenceKey(localStorageKey),
  )

  if (!defaultColumns) defaultColumns = []
  if (!fetchMore) fetchMore = () => {}

  const selectedRowsCount = rowSelection ? Object.keys(rowSelection).length : 0
  const isSelectionLimitReached = selectionLimit ? selectedRowsCount >= selectionLimit : false

  if (rowSelection && defaultColumns[0].id !== "selection") {
    defaultColumns.unshift(
      columnHelper.display({
        id: "selection",
        size: 40,
        header: ({ table }) => {
          const { selectionLimit } = table.options.meta

          if (selectionLimit) {
            return null
          }
          return (
            <CheckBox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          )
        },
        cell: ({ row, table }) => {
          const { isSelectionLimitReached } = table.options.meta
          const disabled = isSelectionLimitReached && !row.getIsSelected()

          return (
            <CheckBox
              checked={row.getIsSelected()}
              disabled={disabled || !row.getCanSelect()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
      }),
    )
  }

  const [sorting, setSorting] = React.useState(defaultSorting ? [defaultSorting] : [])
  const [columns] = React.useState(() => [...defaultColumns])
  const [columnVisibility, setColumnVisibility] = React.useState(() => persistedColumnVisibility)
  const tableContainerRef = React.useRef(null)
  const [tableContainerRefState, setTableContainerRefState] = React.useState(null)
  React.useEffect(() => {
    if (!tableContainerRef) {
      return
    }

    setTableContainerRefState(tableContainerRef)
  }, [tableContainerRef])

  // react-table doesn't pass the new sorting, but an updater function
  const handleSortingChange = React.useCallback(
    (updater) => {
      const newSorting = updater(sorting)

      setSorting(newSorting)

      if (onManualSort) {
        onManualSort(newSorting)
      }
    },
    [onManualSort, sorting],
  )

  if (!rowSelection) rowSelection = {}
  if (!setRowSelection) setRowSelection = () => {}

  // Custom row selection handler that respects the selection limit
  const handleRowSelectionChange = React.useCallback(
    (updater) => {
      if (!selectionLimit) {
        setRowSelection(updater)
        return
      }

      const newSelection = typeof updater === "function" ? updater(rowSelection) : updater
      const newSelectedCount = Object.keys(newSelection).length

      // Only apply the new selection if it doesn't exceed the limit
      if (newSelectedCount <= selectionLimit) {
        setRowSelection(updater)
      }
    },
    [rowSelection, setRowSelection, selectionLimit],
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: onManualSort ? undefined : getSortedRowModel(),
    debugTable: false,
    debugAll: false,
    enableSortingRemoval: false,
    enableRowSelection: true,
    onRowSelectionChange: handleRowSelectionChange,
    enableResizing: true,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: handleSortingChange,
    meta: {
      ...metaData,
      selectionLimit,
      selectedRowsCount,
      isSelectionLimitReached,
    },
    defaultColumn: {
      size: null,
      maxSize: null,
      minSize: null,
    },
    ...rest,
  })

  // When the column visibility changes, sync with local-storage
  React.useEffect(() => {
    if (columnVisibility) {
      setPersistedColumnVisibility(columnVisibility)
    }
  }, [columnVisibility, setPersistedColumnVisibility])

  React.useEffect(() => {
    const bottomCurrent = bottom.current

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && !fetchMoreLoading && !fetchMoreCompleted) {
          fetchMore()
        }
      },
      { threshold: 0.5 },
    )

    if (bottomCurrent) {
      observer.observe(bottomCurrent)
    }
    return () => {
      if (bottomCurrent) {
        observer.unobserve(bottomCurrent)
      }
    }
  }, [fetchMore, loading, fetchMoreLoading, fetchMoreCompleted])

  return (
    <div
      className={cn("c-box overflow-auto", !inline && "h-full", boxClassName)}
      ref={tableContainerRef}
    >
      <TableContext.Provider
        value={{
          tableContainerRef: tableContainerRefState,
          table,
          loading,
          error,
          dataSize: data.length,
          selectionLimit,
          selectedRowsCount,
          isSelectionLimitReached,
        }}
      >
        {header}
        <table
          // has-[.empty-table]:h-[calc(100%-4em)] adds height for empty tables
          className={cn(
            "c-table border-separate tabular-nums has-[.empty-table]:h-[calc(100%-4em)]",
            fixed ? "table-fixed" : "table-auto",
          )}
        >
          {children}
        </table>
        <div ref={bottom} />
      </TableContext.Provider>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  metaData: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  fetchMoreLoading: PropTypes.bool,
  fetchMoreCompleted: PropTypes.bool,
  defaultSorting: PropTypes.object,
  header: PropTypes.node,
  fixed: PropTypes.bool,
  children: PropTypes.node,
  inline: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  boxClassName: PropTypes.string,
  rest: PropTypes.object,
  localStorageKey: PropTypes.string,
  onManualSort: PropTypes.func,
  selectionLimit: PropTypes.number,
}

const TableDefaultStack = () => {
  return (
    <>
      <Table.Headers />
      <Table.Loading />
      <Table.Errors />
      <Table.Empty />
      <Table.Rows />
    </>
  )
}

Table.Headers = Headers
Table.Rows = Rows
Table.VirtualizedRows = VirtualizedRows
Table.Empty = EmptyState
Table.Errors = ErrorState
Table.Loading = LoadingState
Table.DefaultStack = TableDefaultStack
Table.Header = Header
Table.ColumnSelect = ColumnSelect

export { columnHelper }
export default Table
