import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import Timeframes from "#Root/base/timeframes"
dayjs.extend(utc)

export const getBaseTimeRange = (baseRange) => {
  const { from: baseFrom, to: baseTo, timeframe } = baseRange

  if (!baseFrom && !baseTo && timeframe) {
    const range = Timeframes.fromToFromTimeframe(timeframe)
    return { from: range.from, to: range.to }
  } else {
    return { from: baseFrom, to: baseTo }
  }
}

export const getTimeRangeDiffInMs = (baseRange) => {
  const { from: baseFrom, to: baseTo } = baseRange
  return dayjs(baseTo).diff(dayjs(baseFrom))
}

export const getComparisonEnd = (date, baseRange) => {
  const diff = getTimeRangeDiffInMs(baseRange)

  const dateEnd = dayjs(date).add(diff, "millisecond")
  return dateEnd
}
