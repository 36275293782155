import { onCLS, onFCP, onFID, onLCP, onTTFB } from "web-vitals"

const body = document.querySelector("body")

function sendToAnalytics({ id, name, startTime, value, duration, entries }) {
  let perfStartEntry

  if (entries && entries.length) {
    perfStartEntry = entries[0].startTime
  }

  if (!body || !body.dataset.appsignal_frontend_key) return

  const url =
    "https://error-tracker.staging.lol/metrics/webvitals?api_key=" +
    body.dataset.appsignal_frontend_key
  const data = JSON.stringify({
    id,
    name,
    value: value == null ? duration : value,
    startTime: startTime || perfStartEntry,
    label: "web-vital",
  })

  if (navigator.sendBeacon) {
    navigator.sendBeacon.bind(navigator)(url, data)
  } else {
    fetch(url, { body: data, method: "POST", headers: { "Content-Type": "application/json" } })
  }
}

onCLS(sendToAnalytics)
onFID(sendToAnalytics)
onFCP(sendToAnalytics)
onLCP(sendToAnalytics)
onTTFB(sendToAnalytics)
