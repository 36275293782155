import { useTableContext } from "#Root/contexts/TableContext"

const EmptyState = ({ title, content, children }) => {
  const { table, error, loading, dataSize } = useTableContext()

  if (loading) return null
  if (error) return null
  if (dataSize > 0) return null

  return (
    <tbody className="empty-table">
      <tr>
        <td colSpan={table.getFlatHeaders().length} className="c-table__td">
          <div className="flex flex-col w-full h-full justify-center items-center py-4">
            {children !== undefined && children}
            {children === undefined && (
              <>
                <i className="text-2xl mb-4 text-gray-500 fa fa-search" />
                <h2 className="c-heading--base">{title || "No data found"}</h2>
                <p className="text-gray-700 text-center">
                  {content || "We haven't found any data for this timeframe"}
                </p>
              </>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  )
}

EmptyState.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
}

export default EmptyState
