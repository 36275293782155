import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import IncidentNumber from "#Components/IncidentNew/IncidentNumber"
import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import { Table } from "#Root/ui"
import { NumberCell } from "#Root/ui/Table/Cells"
import { columnHelper } from "#Root/ui/Table/Table"
import { maxField } from "#Root/utils/table"

const QUERY = gql`
  query TimeDetectiveErrorPageQuery(
    $appId: String!
    $start: DateTime!
    $end: DateTime!
    $namespaces: [String]!
  ) {
    app(id: $appId) {
      id
      timeDetectiveErrorDataPoints(start: $start, end: $end, namespaces: $namespaces) {
        throughput
        namespace
        actionName
        exceptionName
        incident {
          ... on ExceptionIncident {
            id
            number
            exceptionName
            namespace
            actionNames
            firstBacktraceLine
            errorGroupingStrategy
          }
        }
      }
    }
  }
`

const TimeDetectiveErrorPage = ({ params, namespaces }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      namespaces: namespaces,
    },
  })

  const points = (data?.app?.timeDetectiveErrorDataPoints || [])
    .filter((m) => m.incident && m.incident.__typename == "ExceptionIncident")
    .map(({ incident, ...metric }) => {
      const { id, namespace } = incident
      const actionName =
        (incident.actionNames || []).length > 0
          ? incident.actionNames[0]
          : "Happened outside an action"
      const href = UrlHelper.exceptionIncidentSamplesPagePath({
        number: incident.number,
        time: params.td_start,
      })

      return { ...metric, incident, actionName, namespace, href, id }
    })
  const maxValues = {
    throughput: maxField(points, "throughput"),
  }

  const columns = [
    columnHelper.accessor("actionName", {
      header: "Name",
      size: 300,
      cell: ({ row }) => {
        const { incident, namespace, actionName, href } = row.original
        return (
          <div>
            <div className="flex">
              <Link className="text-blue truncate" href={href} title={incident.exceptionName} />
              <IncidentNumber incident={incident} />
            </div>
            {incident.errorGroupingStrategy == "TYPE_AND_RELEVANT_BACKTRACE_LINE" &&
              incident.firstBacktraceLine && (
                <div className="truncate">
                  <code title={incident.firstBacktraceLine}>{incident.firstBacktraceLine}</code>
                </div>
              )}
            <div className="flex">
              <span className="truncate" title={actionName}>
                {actionName}
              </span>
              <NamespaceLabel
                className={`pt-0.5 ${actionName ? "ml-2" : ""}`}
                namespace={namespace}
              />
            </div>
          </div>
        )
      },
    }),
    columnHelper.accessor("throughput", {
      header: "Count",
      size: 180,
      cell: ({ getValue, table, column }) => (
        <NumberCell
          value={getValue()}
          maxValues={table.options.meta.maxValues}
          columnId={column.id}
        />
      ),
    }),
  ]

  return (
    <Table
      columns={columns}
      data={points}
      loading={loading}
      error={error}
      header={<Table.Header title="Error incidents in this timeframe" />}
      defaultSorting={{ id: "throughput", desc: true }}
      metaData={{ maxValues: maxValues }}
    >
      <Table.Headers />
      <Table.Loading />
      <Table.Errors />
      <Table.Empty />
      <Table.Rows />
    </Table>
  )
}

TimeDetectiveErrorPage.propTypes = {
  params: PropTypes.object.isRequired,
  title: PropTypes.string,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectiveErrorPage
