export const LockButton = ({ locked, onClick, showHint, onMouseLeave }) => {
  const lockClassName = locked || showHint ? "fa fa-fw fa-lock" : "fa fa-fw fa-unlock"

  const label = showHint ? "Click to lock" : ""

  return (
    <button
      className="cursor-pointer text-[10px] text-white bg-[#21375A] px-[7px] py-[5px] uppercase rounded-[3px] whitespace-nowrap"
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      <i className={lockClassName} /> {label}
    </button>
  )
}

LockButton.propTypes = {
  locked: PropTypes.bool,
  onClick: PropTypes.func,
  showHint: PropTypes.bool,
  onMouseLeave: PropTypes.func,
}
