import { ComparisonButton, FullscreenDatePicker } from "./FullscreenDatePicker"

export const ComparisonToolbar = ({ isComparisonAttempted, onClearComparison }) => {
  return (
    <div className="h-[55px] border-b py-4 px-0 border-gray-200 flex items-center justify-between text-xs relative pr-4 pl-4 -ml-4">
      <div className="flex items-center space-x-2">
        <FullscreenDatePicker />
        <ComparisonButton />
      </div>
      {isComparisonAttempted ? (
        <div>
          <button className="c-link" onClick={onClearComparison}>
            Clear comparison
          </button>
        </div>
      ) : null}
    </div>
  )
}

ComparisonToolbar.propTypes = {
  isComparisonAttempted: PropTypes.bool,
  onClearComparison: PropTypes.func,
}
