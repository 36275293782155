import dayjs from "dayjs"

import { useDatePickerContext } from "#Root/contexts/DatePickerContext"
import { useFormatterContext } from "#Root/contexts/FormatterContext"
import cn from "#Root/utils/cn"

import Button from "../Button/Button"
import DateRangeSlider from "../DateRangeSlider/DateRangeSlider"

export const resolutionTypes = {
  HOURLY: "hourly",
  MINUTELY: "minutely",
}

export const DatePickerContainer = ({
  resolution,
  setResolution,
  handleMinutelyApply,
  handleHourlyApply,
  closeCalendar,
  isSubmitDisabled,
  children,
  hideMinutely = false,
  minutelyRangeWindow,
  setMinutelyRangeWindow,
}) => {
  const { range } = useDatePickerContext()
  const { timeZone } = useFormatterContext()

  const [selectedMinutelyRange, setSelectedMinutelyRange] = React.useState(() => {
    if (!range.from || !range.to) {
      return null
    }
    return [range.from.toISOString(), range.to.toISOString()]
  })

  const content =
    resolution === resolutionTypes.HOURLY || hideMinutely ? (
      <div>
        <div className="relative p-3 flex flex-col items-center">{children}</div>
        <div className="flex space-x-3 px-3 pb-3">
          <div className="flex flex-col w-1/2">{<TimeSelectInput name="from" />}</div>
          <div className="flex flex-col w-1/2">
            {<TimeSelectInput name="to" defaultValue="23:59:59" />}
          </div>
        </div>
      </div>
    ) : (
      <div className="relative">
        <MinutelyView
          key={minutelyRangeWindow}
          rangeWindow={minutelyRangeWindow}
          setRangeWindow={setMinutelyRangeWindow}
          onSelect={setSelectedMinutelyRange}
          initialRange={selectedMinutelyRange}
        />
      </div>
    )

  const handleApplyClick = () => {
    if (resolution === resolutionTypes.MINUTELY && selectedMinutelyRange) {
      const [startIso, endIso] = selectedMinutelyRange
      const startDate = dayjs(startIso).tz(timeZone)
      const endDate = dayjs(endIso).tz(timeZone)

      handleMinutelyApply(startDate, endDate)
      return
    }

    // Only call handleApply for hourly resolution
    handleHourlyApply()
  }

  return (
    <div className="c-dropdown p-0 max-h-full -mt-1" key="calendar-container">
      {/* Resolution header picker */}
      {!hideMinutely && <ResolutionSection resolution={resolution} setResolution={setResolution} />}

      {/* Minutely or hourly view */}
      <div>{content}</div>

      {/* Re-used controls */}
      <div className="flex items-center p-3 border-t border-gray-200 space-x-3">
        <Button onClick={handleApplyClick} disabled={isSubmitDisabled}>
          Apply
        </Button>
        <Button onClick={closeCalendar} color="white">
          Cancel
        </Button>
      </div>
    </div>
  )
}

DatePickerContainer.propTypes = {
  handleHourlyApply: PropTypes.func.isRequired,
  handleMinutelyApply: PropTypes.func.isRequired,
  closeCalendar: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  resolution: PropTypes.string.isRequired,
  setResolution: PropTypes.func.isRequired,
  hideMinutely: PropTypes.bool,
  minutelyRangeWindow: PropTypes.number,
  setMinutelyRangeWindow: PropTypes.func,
}

export const ResolutionSection = ({ resolution, setResolution }) => {
  return (
    <div className="p-3 border-b border-gray-200">
      <div className="text-sm font-bold mb-1">Chart Resolution</div>
      <div className="c-button-group">
        <button
          onClick={() => setResolution(resolutionTypes.HOURLY)}
          className={cn("c-button-group__item c-button c-button--sm c-button--white", {
            "c-button--white-inactive": resolution !== resolutionTypes.HOURLY,
          })}
        >
          Hourly (30 Days)
        </button>
        <button
          onClick={() => setResolution(resolutionTypes.MINUTELY)}
          className={cn("relative c-button-group__item c-button c-button--sm c-button--white", {
            "c-button--white-inactive": resolution !== resolutionTypes.MINUTELY,
          })}
        >
          Minutely (48 Hours)
          <div className="size-1.5 bg-azure rounded-full absolute top-1 right-1" />
        </button>
      </div>
    </div>
  )
}

ResolutionSection.propTypes = {
  resolution: PropTypes.string.isRequired,
  setResolution: PropTypes.func.isRequired,
}

const TimeSelectInput = ({ name }) => {
  const { timestamp, setTimestamp } = useDatePickerContext()

  const handleTimestampChange = (e) => {
    setTimestamp({ ...timestamp, [name]: e.target.value })
  }

  return (
    <>
      <label
        htmlFor={`${name}-time`}
        className="ignore-old-css not-italic font-normal text-sm text-gray-500"
      >
        {name}
      </label>
      <input
        key="time-input"
        id={`${name}-time`}
        type="time"
        step="1"
        min="00:00:00"
        max="23:59:59"
        defaultValue={timestamp[name]}
        onBlur={handleTimestampChange}
        className="w-full box-border h-8 bg-gray-100 border-x border-y p-2 rounded"
      />
    </>
  )
}

TimeSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
}

const rangeWindows = [1, 2, 4, 6, 8]

export const MinutelyView = ({ onSelect, initialRange, rangeWindow, setRangeWindow }) => {
  return (
    <div className="min-w-[420px]">
      <div className="border-b border-gray-200 p-3">
        <div className="text-sm font-bold mb-1">Time Range</div>

        <div className="c-button-group">
          {rangeWindows.map((rWindow) => (
            <button
              key={rWindow}
              onClick={() => setRangeWindow(rWindow)}
              className={cn("c-button-group__item c-button c-button--sm c-button--white", {
                "c-button--white-inactive": rangeWindow !== rWindow,
              })}
            >
              {rWindow}H
            </button>
          ))}
        </div>
      </div>
      <div className="p-3">
        <div className="text-sm font-bold mb-1">
          Select time <span className="font-normal text-gray-600">last 48 hours</span>
        </div>
        <div className="mt-1">
          <DateRangeSlider
            windowSize={rangeWindow}
            totalHours={48}
            onSelect={onSelect}
            initialRange={initialRange}
          />
        </div>
      </div>
    </div>
  )
}

MinutelyView.propTypes = {
  onSelect: PropTypes.func.isRequired,
  initialRange: PropTypes.array,
  rangeWindow: PropTypes.number,
  setRangeWindow: PropTypes.func,
}

export default DatePickerContainer
