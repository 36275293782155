const TimeInput = React.forwardRef((_props, timestampRef) => {
  const [timestamp, setTimestamp] = React.useState("00:00:00")

  const handleTimestampChange = (e) => {
    setTimestamp(e.target.value)
    if (timestampRef) timestampRef.current = e.target.value
  }

  return (
    <div className="p-3 pt-0" key="time-container">
      <p className="not-italic font-normal text-sm text-gray-500">Timestamp</p>
      <input
        key="time-input"
        type="time"
        step="1"
        min="00:00:00"
        max="23:59:59"
        value={timestampRef.current || timestamp}
        onChange={handleTimestampChange}
        className="w-full box-border h-8 bg-gray-100 border-x border-y p-2 rounded"
      />
    </div>
  )
})

TimeInput.displayName = "TimeInput"

export default TimeInput
