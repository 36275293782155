import { getBaseTimeRange } from "../utils/timerange"

const FullscreenChartsContext = React.createContext()

export const FullscreenChartsProvider = ({ children, initialRange }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const [chartMetadata, setChartMetadata] = React.useState(null)
  const [baseTimeRange, setBaseTimeRange] = React.useState(() => initialRange)
  const [comparisonTimeRange, setComparisonTimeRange] = React.useState(null)

  const expandChart = React.useCallback((chartMetadata) => {
    setChartMetadata(chartMetadata)
    setBaseTimeRange(
      getBaseTimeRange({
        from: chartMetadata.from,
        to: chartMetadata.to,
        timeframe: chartMetadata.timeframe,
      }),
    )
    setIsOpen(true)
  }, [])

  const close = React.useCallback(() => {
    setIsOpen(false)
    setChartMetadata(null)
    setBaseTimeRange(null)
    setComparisonTimeRange(null)
  }, [])

  const updateBaseTimeRange = React.useCallback((newBaseTimeRange) => {
    setBaseTimeRange(newBaseTimeRange)
    setComparisonTimeRange(null)
  }, [])

  return (
    <FullscreenChartsContext.Provider
      value={{
        isOpen,
        close,
        expandChart,
        chartMetadata,
        baseTimeRange,
        comparisonTimeRange,
        setComparisonTimeRange,
        updateBaseTimeRange,
      }}
    >
      {children}
    </FullscreenChartsContext.Provider>
  )
}

export const useFullscreenChartsContext = () => React.useContext(FullscreenChartsContext)

FullscreenChartsProvider.propTypes = {
  children: PropTypes.node,
  initialRange: PropTypes.object,
}
