import { ComparisonCanvas } from "./components/ComparisonCanvas"
import { EmptyState, ErrorState, LoadingState } from "./components/PlaceholderState"
import { useFullscreenChartsContext } from "./contexts/fullscreenChartsContext"
import {
  BaseTimeseriesContextProvider,
  ComparisonTimeseriesContextProvider,
} from "./contexts/timeseriesContext"
import { useFetchMetricsByTimeframe } from "./utils/useFetchMetricsByTimeframe"

export function FullscreenChart({ height }) {
  const { chartMetadata, baseTimeRange } = useFullscreenChartsContext()
  const { label, queries } = chartMetadata

  const baseTimeseries = useFetchMetricsByTimeframe({
    queries,
    from: baseTimeRange.from,
    to: baseTimeRange.to,
  })

  if (baseTimeseries.loading || Object.keys(baseTimeseries.timeseries).length === 0) {
    return <LoadingState />
  }

  const baseTimeseriesData = baseTimeseries.timeseries.series[0]?.data || []

  if (baseTimeseriesData.length === 0) {
    return <EmptyState />
  }

  if (baseTimeseries.error) {
    return <ErrorState />
  }

  return (
    <div className="h-full">
      <BaseTimeseriesContextProvider timeseries={baseTimeseries.timeseries} label={label}>
        <ComparisonContextWrapper>
          <ComparisonCanvas height={height} />
        </ComparisonContextWrapper>
      </BaseTimeseriesContextProvider>
    </div>
  )
}

FullscreenChart.propTypes = {
  height: PropTypes.number.isRequired,
}

// Splitting the context so that we won't reset the base timeseries when the comparison timeseries is loading
const ComparisonContextWrapper = ({ children }) => {
  const { chartMetadata, comparisonTimeRange } = useFullscreenChartsContext()
  const { label, queries } = chartMetadata

  const comparisonTimeseries = useFetchMetricsByTimeframe({
    queries,
    from: comparisonTimeRange?.from,
    to: comparisonTimeRange?.to,
    enabled: Boolean(comparisonTimeRange),
  })

  // We don't handle empty state here, we will show a banner in the comparison view
  if (comparisonTimeRange) {
    if (comparisonTimeseries.loading || Object.keys(comparisonTimeseries.timeseries).length === 0) {
      return <LoadingState />
    }

    if (comparisonTimeseries.error) {
      return <ErrorState />
    }
  }

  return (
    <ComparisonTimeseriesContextProvider timeseries={comparisonTimeseries.timeseries} label={label}>
      {children}
    </ComparisonTimeseriesContextProvider>
  )
}

ComparisonContextWrapper.propTypes = {
  children: PropTypes.node,
}
