import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import { FullscreenDatePicker } from "./FullscreenDatePicker"

const ChartStateWrapper = ({ headerContent, message, children }) => (
  <div className="grid grid-cols-12 h-full">
    <div className="col-span-9">
      <div className="h-[55px] border-b py-4 px-0 border-gray-200 flex items-center justify-between text-xs pl-4">
        {headerContent}
      </div>
      <div className="relative top-2 flex items-center justify-center h-full">
        {children || message}
      </div>
    </div>
    <div className="col-span-3 border-l border-l-gray-200" />
  </div>
)

export const LoadingState = () => (
  <ChartStateWrapper>
    <LoadingAnimation message="Loading" />
  </ChartStateWrapper>
)

export const EmptyState = () => (
  <ChartStateWrapper headerContent={<FullscreenDatePicker />} message="No data available" />
)

export const ErrorState = () => (
  <ChartStateWrapper
    headerContent={<FullscreenDatePicker />}
    message="Uh oh! There was an error :("
  />
)

ChartStateWrapper.propTypes = {
  headerContent: PropTypes.node,
  message: PropTypes.string,
  children: PropTypes.node,
}
