import useMeasure from "#Root/hooks/useMeasure"
import { LINE } from "#Root/ui/ASChart/constants"
import Overlay from "#Root/ui/Overlay"

import { useFullscreenChartsContext } from "./contexts/fullscreenChartsContext"
import { FullscreenChart } from "./FullscreenChart"

const UnsupportedChartMessage = () => (
  <div className="c-overlay__content max-h-none h-full bg-white">
    <div className="h-full flex items-center justify-center">Only line charts are supported</div>
  </div>
)

const FullscreenOverlayContent = () => {
  const { chartMetadata } = useFullscreenChartsContext()

  const [ref, rect] = useMeasure()
  const chartHeight = rect.height

  if (chartMetadata.renderer && chartMetadata.renderer !== LINE) {
    return <UnsupportedChartMessage />
  }

  return (
    <div className="c-overlay__content overflow-hidden max-h-none h-full bg-white">
      <div ref={ref} className="max-h-none h-full">
        {chartMetadata && chartHeight > 0 ? <FullscreenChart height={chartHeight} /> : null}
      </div>
    </div>
  )
}
const FullscreenOverlay = () => {
  const { isOpen, close, chartMetadata } = useFullscreenChartsContext()

  if (!isOpen) {
    return
  }

  return (
    <Overlay open={true} onClose={close} size="fullscreen">
      <Overlay.Title handleClose={close} title={chartMetadata.title} />
      <FullscreenOverlayContent />
    </Overlay>
  )
}

export default FullscreenOverlay
