import FormattedTime from "#Root/formatters/FormattedTime"
import SortingMenu from "#Root/ui/ASChart/components/Hover/SortingMenu"
import { SORT_ASC, SORT_NAME } from "#Root/ui/ASChart/constants"

import { useFullscreenChartsContext } from "../../contexts/fullscreenChartsContext"
import {
  useBaseTimeseriesContext,
  useComparisonTimeseriesContext,
} from "../../contexts/timeseriesContext"
import { SeriesList } from "./SeriesList"

const sortSeries = ({ series, sortBy, sortDirection, dataIndex }) => {
  return series
    .map((serie) => ({
      id: serie.id,
      value: serie.data[dataIndex].y,
      label: serie.label,
    }))
    .sort((a, b) => {
      if (sortBy === SORT_NAME) {
        return sortDirection === SORT_ASC
          ? a.label.localeCompare(b.label)
          : b.label.localeCompare(a.label)
      }
      return sortDirection === SORT_ASC ? a.value - b.value : b.value - a.value
    })
}

const Hover = ({
  dataIndex,
  invertDate,
  comparisonInvertDate,
  comparisonDataIndex,
  sorting,
  setSorting,
  colors,
  toggleHoveredLines,
  height,
  valuePrecision,
}) => {
  const linesList = React.useRef()
  const { chartMetadata } = useFullscreenChartsContext()
  const baseSeries = useBaseTimeseriesContext()
  const comparisonSeries = useComparisonTimeseriesContext()
  const { sortBy, sortDirection } = sorting

  const sortedBaseVisibleSeries = sortSeries({
    series: baseSeries.visibleSeries,
    sortBy,
    sortDirection,
    dataIndex,
  })

  const sortedComparisonVisibleSeries =
    comparisonSeries.isComparisonPresent && comparisonDataIndex !== null
      ? sortSeries({
          series: comparisonSeries.visibleSeries,
          sortBy,
          sortDirection,
          dataIndex: comparisonDataIndex,
        })
      : []

  const sortedBaseDisabledSeries = sortSeries({
    series: baseSeries.disabledSeries,
    sortBy,
    sortDirection,
    dataIndex,
  })

  const sortedComparisonDisabledSeries =
    comparisonSeries.isComparisonPresent && comparisonDataIndex !== null
      ? sortSeries({
          series: comparisonSeries.disabledSeries,
          sortBy,
          sortDirection,
          dataIndex: comparisonDataIndex,
        })
      : []

  const unhideAllLines = () => {
    baseSeries.enableAllLines()
    comparisonSeries.enableAllLines()
    setTimeout(() => linesList.current?.scrollTo(0, 0), 0)
  }

  const sharedProps = {
    toggleHoveredLines,
    colors,
    valueFormat: chartMetadata.valueFormat,
    valueInput: chartMetadata.valueInput,
    resolution: baseSeries.timeseries.resolution,
    valuePrecision,
    invertDate,
    comparisonInvertDate,
  }

  return (
    <div className="h-full">
      <header className="py-4 px-3 border-b border-gray-200 flex items-center justify-between text-xs font-semibold">
        <div className="flex space-x-2 items-center">
          {comparisonSeries.isComparisonPresent ? (
            <div>Comparison</div>
          ) : (
            <FormattedTime
              format={baseSeries.timeseries.resolution === "DAILY" ? "dateLong" : "weekday"}
              value={invertDate}
            />
          )}
        </div>
        <SortingMenu sorting={sorting} onChange={setSorting} />
      </header>
      <section className="overflow-y-scroll custom-scrollbar" style={{ height }}>
        <SeriesList
          series={sortedBaseVisibleSeries}
          comparisonSeries={sortedComparisonVisibleSeries}
          isComparisonPresent={comparisonSeries.isComparisonPresent}
          {...sharedProps}
        />
        {baseSeries.disabledSeries.length > 0 && (
          <div className="sticky -bottom-px flex items-center justify-between gap-2 border-y border-gray-200 bg-gray-100 px-4 py-1 z-10">
            <span>{baseSeries.disabledSeries.length} hidden</span>
            <button className="text-blue-500 underline" onClick={unhideAllLines}>
              show all
            </button>
          </div>
        )}
        {sortedBaseDisabledSeries.length > 0 && (
          <SeriesList
            series={sortedBaseDisabledSeries}
            comparisonSeries={sortedComparisonDisabledSeries}
            isComparisonPresent={comparisonSeries.isComparisonPresent}
            {...sharedProps}
          />
        )}
      </section>
    </div>
  )
}

Hover.propTypes = {
  dataIndex: PropTypes.number,
  invertDate: PropTypes.instanceOf(Date),
  comparisonDataIndex: PropTypes.number,
  comparisonInvertDate: PropTypes.instanceOf(Date),
  sorting: PropTypes.shape({
    sortBy: PropTypes.oneOf(["name", "value"]),
    sortDirection: PropTypes.oneOf(["asc", "desc"]),
  }).isRequired,
  setSorting: PropTypes.func.isRequired,
  colors: PropTypes.func.isRequired,
  toggleHoveredLines: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  valuePrecision: PropTypes.number.isRequired,
}

export default Hover
