import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import UrlHelper from "#Components/shared/url_helper"
import { SEVERITIES } from "#Root/constants/Logs"
import { Table } from "#Root/ui"
import { NumberCell } from "#Root/ui/Table/Cells/Cells"
import { columnHelper } from "#Root/ui/Table/Table"
import { maxField } from "#Root/utils/table"
import { resolutionEnum } from "#Root/utils/TimeDetective"

const QUERY = gql`
  query TimeDetectiveOverlayLogsQuery(
    $appId: String!
    $start: PreciseDateTime
    $end: PreciseDateTime
    $resolution: ResolutionEnum
  ) {
    app(id: $appId) {
      id
      logs {
        sources {
          id
          name
          metrics(start: $start, end: $end, resolution: $resolution) {
            series {
              digest
              name
            }
            values {
              values {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`

const TimeDetectiveLogsPage = ({ params }) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      resolution: resolutionEnum(params.td_start),
      limit: 1000,
    },
  })

  const mappedData = (data?.app?.logs?.sources || []).map(({ metrics, ...source }) => {
    const fields = {}
    for (const severity of SEVERITIES) {
      const field = severity.value.toLowerCase()
      const digest = metrics.series.find((s) => s.name == field)?.digest
      const count = digest
        ? (metrics.values[0]?.values || []).find((v) => v.key == `${digest};counter`)?.value || 0
        : 0
      fields[field] = count
    }
    return { ...fields, ...source }
  })
  const maxValues = {
    unknown: maxField(mappedData, "unknown"),
    trace: maxField(mappedData, "trace"),
    debug: maxField(mappedData, "debug"),
    info: maxField(mappedData, "info"),
    notice: maxField(mappedData, "notice"),
    warn: maxField(mappedData, "warn"),
    error: maxField(mappedData, "error"),
    critical: maxField(mappedData, "critical"),
    alert: maxField(mappedData, "alert"),
    fatal: maxField(mappedData, "fatal"),
  }

  /* eslint-disable react/prop-types */
  const NumberCellFn = ({ getValue, table, column, row }) => {
    const { start, maxValues } = table.options.meta
    const dataKey = column.id

    return (
      <a
        href={UrlHelper.logsPagePath({
          timestamp: start,
          sourceIds: row.original.id,
          severities: dataKey.toUpperCase(),
        })}
        className="c-link"
      >
        <NumberCell value={getValue()} maxValues={maxValues} columnId={column.id} />
      </a>
    )
  }

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      minSize: 200,
      cell: ({ row, table }) => {
        const { id, name } = row.original

        return (
          <div className="c-virtual-table__row-cell-content">
            <a
              href={UrlHelper.logsPagePath({ timestamp: table.options.meta.start, sourceIds: id })}
              className="c-link"
            >
              {name}
            </a>
          </div>
        )
      },
      sortingFn: (rowA, rowB) => rowA.original.name.localeCompare(rowB.original.name),
    }),
    columnHelper.accessor("unknown", {
      header: "Unknown",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("trace", {
      header: "Trace",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("debug", {
      header: "Debug",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("info", {
      header: "Info",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("notice", {
      header: "Notice",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("warn", {
      header: "Warn",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("error", {
      header: "Error",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("critical", {
      header: "Critical",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("alert", {
      header: "Alert",
      cell: NumberCellFn,
      enableSorting: false,
    }),
    columnHelper.accessor("fatal", {
      header: "Fatal",
      cell: NumberCellFn,
      enableSorting: false,
    }),
  ]

  return (
    <Table
      columns={columns}
      header={<Table.Header title="Log lines per source in this timeframe" />}
      loading={loading}
      error={error}
      metaData={{ maxValues, start: params.td_start }}
      defaultSorting={{ id: "name", desc: false }}
      data={mappedData}
      fixed
    >
      <Table.Headers />
      <Table.Loading />
      <Table.Errors />
      <Table.Empty />
      <Table.VirtualizedRows />
    </Table>
  )
}

TimeDetectiveLogsPage.propTypes = {
  params: PropTypes.object.isRequired,
}

export default TimeDetectiveLogsPage
