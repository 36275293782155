import { bisector } from "d3"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import useMeasure from "#Root/hooks/useMeasure"
import { MarkersCore } from "#Root/ui/ASChart/components/Markers"
import { LineCore } from "#Root/ui/ASChart/components/visuals/Line"
import { XAxisCore } from "#Root/ui/ASChart/components/XAxis"
import { YAxisCore } from "#Root/ui/ASChart/components/YAxis"
import {
  DEFAULT_CHART_HEIGHT,
  MARKERS_HEIGHT,
  SORT_DESC,
  SORT_VALUE,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "#Root/ui/ASChart/constants"

import { useFullscreenChartsContext } from "../contexts/fullscreenChartsContext"
import {
  useBaseTimeseriesContext,
  useComparisonTimeseriesContext,
} from "../contexts/timeseriesContext"
import { getChartSeriesMetadata, getCombinedSeriesMetadata } from "../utils/chartMetadata"
import { ComparisonToolbar } from "./ComparisonToolbar"
import HighlightLine from "./HighlightLine"
import Hover from "./HoverPanel"

export const CONTROL_BAR_HEIGHT = 55
export const TOP_OFFSET = 8
export const HOVER_ACCEPTABLE_RANGE = 10

export const ComparisonCanvas = ({ height: providedHeight = DEFAULT_CHART_HEIGHT }) => {
  const canvasRef = React.useRef()

  const [measureRef, { width }] = useMeasure()

  const { chartMetadata, comparisonTimeRange, setComparisonTimeRange } =
    useFullscreenChartsContext()
  const { settings } = useChartSettingsContext()
  const baseGraph = useBaseTimeseriesContext()
  const comparisonGraph = useComparisonTimeseriesContext()

  const [hoveredLines, setHoveredLines] = React.useState([])
  const [hoverInfo, setHoverInfo] = React.useState(null)
  const [locked, setLocked] = React.useState(false)
  const [sorting, setSorting] = React.useState({ sortBy: SORT_VALUE, sortDirection: SORT_DESC })

  const height = providedHeight - (CONTROL_BAR_HEIGHT + TOP_OFFSET)

  const isComparisonPresent = comparisonGraph.isComparisonPresent
  const isComparisonAttempted = Boolean(comparisonTimeRange)

  const canvasWidth = width - Y_AXIS_WIDTH
  const canvasHeight = isComparisonPresent
    ? height - X_AXIS_HEIGHT * 2
    : height - X_AXIS_HEIGHT - MARKERS_HEIGHT

  const baseChartMeta = getChartSeriesMetadata({
    timeseries: baseGraph.timeseries,
    disabledLines: baseGraph.disabledLines,
    canvasHeight,
    canvasWidth,
    settings,
  })

  const comparisonChartMeta = isComparisonPresent
    ? getChartSeriesMetadata({
        timeseries: comparisonGraph.timeseries,
        disabledLines: comparisonGraph.disabledLines,
        canvasHeight,
        canvasWidth,
        settings,
      })
    : null

  const combinedChartMetadata = isComparisonPresent
    ? getCombinedSeriesMetadata({
        baseYMax: baseChartMeta.yMax,
        baseYMin: baseChartMeta.yMin,
        comparisonYMax: comparisonChartMeta.yMax,
        comparisonYMin: comparisonChartMeta.yMin,
        canvasHeight,
      })
    : null

  const valuePrecision = isComparisonPresent
    ? combinedChartMetadata.valuePrecision
    : baseChartMeta.valuePrecision

  const handleMouseMove = (event) => {
    if (locked) {
      return
    }

    if (!canvasRef.current) {
      return
    }

    const elPos = canvasRef.current.getBoundingClientRect()
    const mouseX = event.clientX - elPos.left

    const invert = baseChartMeta.xScale.invert(mouseX)
    const comparisonInvert = isComparisonPresent ? comparisonChartMeta.xScale.invert(mouseX) : null

    const bisectDate = bisector((d) => d.x).center
    const dataIndex = bisectDate(baseGraph.timeseries.series[0].data, invert, 1)
    const comparisonDataIndex = isComparisonPresent
      ? bisectDate(comparisonGraph.timeseries.series[0].data, comparisonInvert, 1)
      : null

    if (mouseX <= 0) {
      setHoverInfo({
        invertDate: baseGraph.timeseries.series[0].data[0].x,
        dataIndex: 0,
        comparisonInvertDate: isComparisonPresent
          ? comparisonGraph.timeseries.series[0].data[0].x
          : null,
        comparisonDataIndex: isComparisonPresent ? 0 : null,
      })
      return
    }

    if (dataIndex >= 0 && dataIndex < baseGraph.timeseries.series[0].data.length) {
      const dataPoint = baseGraph.timeseries.series[0].data[dataIndex]
      const dataPointX = baseChartMeta.xScale(dataPoint.x)

      const comparisonDataPoint = isComparisonPresent
        ? comparisonGraph.timeseries.series[0].data[comparisonDataIndex]
        : null
      const comparisonDataPointX = isComparisonPresent
        ? comparisonChartMeta.xScale(comparisonDataPoint.x)
        : null

      const isWithinAcceptableRange = Math.abs(dataPointX - mouseX) <= HOVER_ACCEPTABLE_RANGE
      const isComparisonWithinAcceptableRange = isComparisonPresent
        ? Math.abs(comparisonDataPointX - mouseX) <= HOVER_ACCEPTABLE_RANGE
        : null

      const finalInvertDate = isWithinAcceptableRange ? dataPoint.x : null

      if (!finalInvertDate) {
        return null
      }

      setHoverInfo({
        invertDate: finalInvertDate,
        dataIndex,
        comparisonInvertDate: isComparisonPresent
          ? isComparisonWithinAcceptableRange
            ? comparisonDataPoint.x
            : null
          : null,
        comparisonDataIndex: isComparisonPresent ? comparisonDataIndex : null,
      })
      return
    }

    return null
  }

  return (
    <div className="grid grid-cols-12 h-full pl-4">
      <div className="col-span-9">
        <ComparisonToolbar
          isComparisonAttempted={isComparisonAttempted}
          onClearComparison={() => setComparisonTimeRange(null)}
        />
        <div ref={measureRef} className="relative top-2">
          {isComparisonAttempted && !isComparisonPresent ? (
            <div className="absolute -top-2 left-0 w-[calc(100%_+_16px)] h-8 flex items-center justify-start text-sm bg-yellow-200 text-yellow-800 z-10 -ml-4 pl-8">
              No data available for comparison. Try selecting a different timeframe.
            </div>
          ) : null}

          <div style={{ height: height }} className="relative">
            {isComparisonPresent ? (
              <XAxisCore
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
                showGridLines={false}
                top={0}
                width={width}
                xAxisHeight={X_AXIS_HEIGHT}
                xDomain={comparisonChartMeta.xDomain}
                xScale={comparisonChartMeta.xScale}
                yAxisWidth={0}
              />
            ) : null}

            <YAxisCore
              gridLinesTop={isComparisonPresent ? X_AXIS_HEIGHT : 0}
              top={isComparisonPresent ? X_AXIS_HEIGHT : 0}
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              resolution={baseGraph.timeseries.resolution}
              yAxisWidth={Y_AXIS_WIDTH}
              valueFormat={chartMetadata.valueFormat}
              valueInput={chartMetadata.valueInput}
              valuePrecision={valuePrecision}
              yDomain={isComparisonPresent ? combinedChartMetadata.yDomain : baseChartMeta.yDomain}
              yScale={isComparisonPresent ? combinedChartMetadata.yScale : baseChartMeta.yScale}
            />

            <svg
              onMouseMove={(event) => handleMouseMove(event)}
              onClick={() => setLocked((prev) => !prev)}
              ref={canvasRef}
              style={{
                position: "absolute",
                top: isComparisonPresent ? X_AXIS_HEIGHT : 0,
                left: width - canvasWidth,
                width: canvasWidth,
                height: canvasHeight,
              }}
            >
              {baseGraph.visibleSeries.map((serie) => {
                return (
                  <LineCore
                    key={serie.id}
                    {...serie}
                    xScale={baseChartMeta.xScale}
                    yScale={baseChartMeta.yScale}
                    yDomain={
                      isComparisonPresent ? combinedChartMetadata.yDomain : baseChartMeta.yDomain
                    }
                    xDomain={baseChartMeta.xDomain}
                    color={baseChartMeta.colors({ label: serie.label })}
                    isActive={hoveredLines.includes(serie.id) || hoveredLines.length === 0}
                  />
                )
              })}

              {isComparisonPresent
                ? comparisonGraph.visibleSeries.map((serie) => {
                    return (
                      <LineCore
                        dotted
                        key={serie.id}
                        {...serie}
                        xScale={comparisonChartMeta.xScale}
                        yScale={comparisonChartMeta.yScale}
                        yDomain={comparisonChartMeta.yDomain}
                        xDomain={comparisonChartMeta.xDomain}
                        color={baseChartMeta.colors({ label: serie.label })}
                        isActive={hoveredLines.includes(serie.id) || hoveredLines.length === 0}
                      />
                    )
                  })
                : null}
            </svg>
            <XAxisCore
              top={isComparisonPresent ? canvasHeight + X_AXIS_HEIGHT : canvasHeight}
              gridLinesTop={isComparisonPresent ? X_AXIS_HEIGHT : 0}
              showGridLines={settings.verticalSupportLines}
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              xAxisHeight={X_AXIS_HEIGHT}
              width={width}
              xDomain={baseChartMeta.xDomain}
              xScale={baseChartMeta.xScale}
              yAxisWidth={Y_AXIS_WIDTH}
            />
            {!isComparisonPresent ? (
              <MarkersCore
                yAxisOffset={Y_AXIS_WIDTH}
                xScale={baseChartMeta.xScale}
                xDomain={baseChartMeta.xDomain}
                className="absolute bottom-0 w-[calc(100%+16px)] z-10 pl-4 -ml-4"
              />
            ) : null}
            {hoverInfo ? (
              <HighlightLine
                invertDate={hoverInfo.invertDate}
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
                width={width}
                xScale={baseChartMeta.xScale}
                xDomain={baseChartMeta.xDomain}
                top={isComparisonPresent ? canvasHeight + X_AXIS_HEIGHT : canvasHeight}
                locked={locked}
                toggleLock={() => setLocked((prev) => !prev)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-span-3 border-l border-l-gray-200 h-full">
        {hoverInfo ? (
          <Hover
            dataIndex={hoverInfo.dataIndex}
            invertDate={hoverInfo.invertDate}
            comparisonDataIndex={hoverInfo.comparisonDataIndex}
            comparisonInvertDate={hoverInfo.comparisonInvertDate}
            sorting={sorting}
            setSorting={setSorting}
            colors={baseChartMeta.colors}
            toggleHoveredLines={setHoveredLines}
            height={height}
            valuePrecision={valuePrecision}
          />
        ) : null}
      </div>
    </div>
  )
}

ComparisonCanvas.propTypes = {
  height: PropTypes.number,
}
