import isObject from "lodash.isobject"

import { decamelize } from "#Root/utils/string"
// Convert to JSON and parse it back again so we have a mutable object.
// FIXME: If you know of a better way to do this for the entire object from
// React props, please fix this!
export const clone = function (object) {
  return JSON.parse(JSON.stringify(object))
}

export function cleanObject(input, invalidKeys = [], invalidValues = [], recursive = false) {
  if (Array.isArray(input)) {
    return input.map((value) =>
      recursive ? cleanObject(value, invalidKeys, invalidValues, recursive) : value,
    )
  } else if (isObject(input)) {
    const cleanedObject = {}
    for (const [key, value] of Object.entries(input)) {
      if (invalidKeys.includes(key)) {
        continue
      }
      if (invalidValues.includes(value)) {
        continue
      }

      cleanedObject[key] = recursive
        ? cleanObject(value, invalidKeys, invalidValues, recursive)
        : value
    }
    return cleanedObject
  } else {
    return input
  }
}

export function decamelizeObject(input, recursive = false) {
  if (Array.isArray(input)) {
    return input.map((value) => (recursive ? decamelizeObject(value, recursive) : value))
  } else if (isObject(input)) {
    const decamelizedObject = {}
    for (const [key, value] of Object.entries(input)) {
      decamelizedObject[decamelize(key)] = recursive ? decamelizeObject(value) : value
    }
    return decamelizedObject
  } else {
    return input
  }
}
