function updateVatNumberVisibility() {
  const billingCountryElement = $(".js-billing-country")
  const billingVatNumberElement = $(".js-billing-vat-number")
  const euCountries = billingCountryElement.data("euCountries")

  const selectedCountry = billingCountryElement.val()
  if (euCountries.includes(selectedCountry)) {
    billingVatNumberElement.show()
  } else {
    billingVatNumberElement.hide()
  }
}

$(function () {
  const billingCountryElement = $(".js-billing-country")
  if (billingCountryElement.length > 0) {
    updateVatNumberVisibility()

    billingCountryElement.on("change", function () {
      updateVatNumberVisibility()
    })
  }
})
