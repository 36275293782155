import { cn } from "#Root/utils/cn"

const DescriptionList = ({ children, noBorder }) => {
  const classes = ["c-description-list"]
  if (noBorder) {
    classes.push("border-0")
  }

  return <dl className={classes.filter((f) => f).join(" ")}>{children}</dl>
}

DescriptionList.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
}

const DescriptionListTerm = ({ children, className }) => (
  <dt className={cn("c-description-list__label", className)}>{children}</dt>
)

DescriptionListTerm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const DescriptionListDescription = ({ children, className }) => (
  <dd className={cn("c-description-list__value", className)}> {children}</dd>
)

DescriptionListDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

DescriptionList.Term = DescriptionListTerm
DescriptionList.Description = DescriptionListDescription
DescriptionList.Label = DescriptionListTerm
DescriptionList.Value = DescriptionListDescription

export default DescriptionList
