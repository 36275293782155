import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import { findTagValue } from "../../../../utils/metric_keys"
import Link from "../../../shared/link"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostDiskIO = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const { comparisonOperator, value } = trigger.thresholdCondition
  const tags = alertTags || trigger.tags

  let label = "Host disk IO"
  if (tags) {
    const disk = findTagValue(tags, "disk")
    if (disk !== "*") {
      label += ` for "${disk}"`
    }
  }
  label = labelWithHostnameFromTags(label, tags)

  return (
    <>
      <div className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </div>
      <p className="pt-px text-gray-700">
        {trigger.metricName === "disk_io_read" ? "Read" : "Write"}/min
        <FormattedComparisonOperator value={comparisonOperator} />
        <FormattedFileSize value={value} />
      </p>
    </>
  )
}
TriggerHostDiskIO.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostDiskIO
