import FormattedTime from "#Root/formatters/FormattedTime/FormattedTime"
import { getLabel } from "#Root/ui/ASChart/components/Hover/Row"
import cn from "#Root/utils/cn"

import {
  useBaseTimeseriesContext,
  useComparisonTimeseriesContext,
} from "../../contexts/timeseriesContext"

export const SingleView = ({
  serie,
  valueFormat = "number",
  valueInput,
  valuePrecision = 2,
  resolution,
  colors,
  toggleHoveredLines,
}) => {
  const { disabledLines, toggleSelectedLine } = useBaseTimeseriesContext()

  const disabled = disabledLines.includes(serie.id)
  const value = disabled ? 0 : serie.value
  const color = colors(serie)

  return (
    <div
      className="px-3 cursor-pointer text-gray-600 py-1 flex justify-between"
      onClick={(e) => toggleSelectedLine(serie.id, e.metaKey || e.altKey)}
      onMouseEnter={() => toggleHoveredLines([serie.id])}
      onMouseLeave={() => toggleHoveredLines([])}
    >
      <div className="flex mr-2 items-center">
        <div
          className={cn("h-3 w-3 rounded-full flex-shrink-0 mr-2 border-2 bg-white", {
            "border-gray-400": disabled,
          })}
          style={disabled ? {} : { borderColor: color }}
        ></div>
        <div className="truncate">{serie.label}</div>
      </div>
      <div>
        <div className="ml-2 whitespace-nowrap tabular-nums text-right">
          {getLabel({
            value,
            valueFormat,
            valueInput,
            valuePrecision,
            resolution,
          })}
        </div>
      </div>
    </div>
  )
}

export const ComparisonView = ({
  baseSerie,
  comparisonSerie,
  valueFormat = "number",
  valueInput,
  valuePrecision = 2,
  resolution,
  colors,
  toggleHoveredLines,
  comparisonInvertDate,
  invertDate,
}) => {
  const { disabledLines: baseDisabledSeries, toggleSelectedLine: toggleBaseDisabledSeries } =
    useBaseTimeseriesContext()
  const {
    disabledLines: comparisonDisabledSeries,
    toggleSelectedLine: toggleComparisonDisabledSeries,
  } = useComparisonTimeseriesContext()

  const isComparisonAvailable = comparisonSerie !== undefined

  const isBaseDisabled = baseDisabledSeries.includes(baseSerie.id)
  const isComparisonDisabled = isComparisonAvailable
    ? comparisonDisabledSeries.includes(comparisonSerie.id)
    : true

  const value = isBaseDisabled ? 0 : baseSerie.value
  const comparisonValue = isComparisonDisabled ? 0 : comparisonSerie.value

  const color = colors(baseSerie)

  return (
    <div
      className="px-3 cursor-pointer text-gray-600 py-1"
      onClick={(e) => {
        toggleBaseDisabledSeries(baseSerie.id, e.metaKey || e.altKey)
        if (isComparisonAvailable) {
          toggleComparisonDisabledSeries(comparisonSerie.id, e.metaKey || e.altKey)
        }
      }}
      onMouseEnter={() =>
        isComparisonAvailable
          ? toggleHoveredLines([baseSerie.id, comparisonSerie.id])
          : toggleHoveredLines([baseSerie.id])
      }
      onMouseLeave={() => toggleHoveredLines([])}
    >
      <div className="flex mr-2 items-center">
        <div
          className={cn("h-3 w-3 rounded-full flex-shrink-0 mr-2 border-2 bg-white", {
            "border-gray-400": isBaseDisabled,
          })}
          style={isBaseDisabled ? {} : { borderColor: color }}
        ></div>
        <div className="truncate">{baseSerie.label}</div>
      </div>
      <div className="flex items-center pl-5 justify-between">
        <div className="flex mr-2 items-center">
          <div
            className="w-3 h-0.5 border mr-2"
            style={isBaseDisabled ? {} : { borderColor: color }}
          />
          <FormattedTime
            format={resolution == "DAILY" ? "dateLong" : "weekday"}
            value={invertDate}
          />
        </div>{" "}
        <div className="ml-2 whitespace-nowrap tabular-nums text-right">
          {getLabel({
            value,
            valueFormat,
            valueInput,
            valuePrecision,
            resolution,
          })}
        </div>
      </div>
      <div className="flex items-center pl-5 justify-between">
        <div className="flex mr-2 items-center">
          <div
            className="w-3 h-0.5 border-dashed border mr-2"
            style={isComparisonDisabled ? {} : { borderColor: color }}
          />
          {isComparisonAvailable && comparisonInvertDate ? (
            <FormattedTime
              format={resolution == "DAILY" ? "dateLong" : "weekday"}
              value={comparisonInvertDate}
            />
          ) : (
            <div>No data</div>
          )}
        </div>
        {isComparisonAvailable ? (
          <div className="ml-2 whitespace-nowrap tabular-nums text-right">
            {getLabel({
              value: comparisonValue,
              valueFormat,
              valueInput,
              valuePrecision,
              resolution,
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

SingleView.propTypes = {
  serie: PropTypes.object.isRequired,
  valueFormat: PropTypes.string.isRequired,
  valueInput: PropTypes.string,
  valuePrecision: PropTypes.number,
  resolution: PropTypes.string.isRequired,
  colors: PropTypes.func.isRequired,
  toggleHoveredLines: PropTypes.func,
}

ComparisonView.propTypes = {
  baseSerie: PropTypes.object.isRequired,
  comparisonSerie: PropTypes.object,
  valueFormat: PropTypes.string.isRequired,
  valueInput: PropTypes.string,
  valuePrecision: PropTypes.number,
  resolution: PropTypes.string.isRequired,
  colors: PropTypes.func.isRequired,
  toggleHoveredLines: PropTypes.func,
  comparisonInvertDate: PropTypes.instanceOf(Date),
  invertDate: PropTypes.instanceOf(Date),
}
