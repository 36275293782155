import { hashCode } from "#Root/utils/react_utils"

import { RELATIVE_RENDERS, STACKED_RENDERS } from "../constants"
import { useDisabledLines } from "../utils/hooks"
import {
  formatSerieLabels,
  transformSeriesToRelative,
  transformSeriesToStacked,
} from "../utils/util"

export const ChartDataContext = React.createContext()

export const ChartDataProvider = ({
  children,
  timeseries = {},
  loading,
  error,
  label,
  renderer = "line",
  minYAxis: yAxisMin,
}) => {
  const { disabledLines, toggleSelectedLine, enableAllLines } = useDisabledLines(
    (timeseries?.series ?? []).map((s) => s.id),
    [],
  )

  let timeseriesNext = timeseries

  let visibleSeries = timeseries.series || []
  let disabledSeries = []

  if (!loading && !error && visibleSeries?.length > 0) {
    // Update the yMin and yMax values for each serie, if we have overrides
    if (typeof yAxisMin === "number") {
      timeseriesNext = {
        ...timeseries,
        series: timeseries.series.map((serie) => ({
          ...serie,
          yMin: Math.min(yAxisMin, serie.yMin),
        })),
      }
    }

    ;[visibleSeries, disabledSeries] = visibleSeries.reduce(
      // Partition the series into enabled and disabled
      ([acc, disabled], serie) => {
        if (disabledLines.includes(serie.id)) {
          return [acc, [...disabled, serie]]
        }
        return [[...acc, serie], disabled]
      },
      [[], []],
    )

    visibleSeries = formatSerieLabels({ series: visibleSeries, label })
    disabledSeries = formatSerieLabels({ series: disabledSeries, label })

    if (RELATIVE_RENDERS.includes(renderer)) {
      visibleSeries = transformSeriesToRelative(visibleSeries)
    } else if (STACKED_RENDERS.includes(renderer)) {
      visibleSeries = transformSeriesToStacked(visibleSeries)
    }
  }

  return (
    <React.Fragment key={hashCode(timeseriesNext)}>
      <ChartDataContext.Provider
        value={{
          loading,
          error,
          yAxisMin,
          disabledLines,
          toggleSelectedLine,
          enableAllLines,
          timeseries: timeseriesNext,
          renderer,
          visibleSeries,
          disabledSeries,
        }}
      >
        {children}
      </ChartDataContext.Provider>
    </React.Fragment>
  )
}

ChartDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  yMaxOverride: PropTypes.number,
  yMinOverride: PropTypes.number,
  timeseries: PropTypes.shape({
    resolution: PropTypes.string,
    xMax: PropTypes.any,
    xMin: PropTypes.any,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fields: PropTypes.string,
        name: PropTypes.string,
        tags: PropTypes.object,
        yMax: PropTypes.number,
        yMin: PropTypes.number,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.any,
            y: PropTypes.number,
          }),
        ),
      }),
    ),
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  transformer: PropTypes.func,
  options: PropTypes.object,
  label: PropTypes.string,
  renderer: PropTypes.oneOf(["line", "bar", "area", "area-relative"]),
  minYAxis: PropTypes.number,
  maxYAxis: PropTypes.number,
}

export const useChartDataContext = () => React.useContext(ChartDataContext)
